import { Component, Injector } from '@angular/core';
import { NgControl } from '@angular/forms';
import { UserDropdownComponentConfigurator } from './user-dropdown-component.configurator';
import { BaseComboboxDropdownComponent } from '@pl/pl-lib/forms';
import { User } from 'src/app/core/models/user';

@Component({
  selector: 'app-user-searchable-dropdown',
  templateUrl:
    '../../../../../../node_modules/@pl/pl-lib/assets/templates/searchable-dropdown/combobox-dropdown.component.html',
  styleUrls: [
    '../../../../plLibSetup/components/searchable-dropdown/combobox-dropdown.component.scss'
  ]
})
export class UserSearchableDropdownComponent extends BaseComboboxDropdownComponent<User> {
  constructor(ngControl: NgControl, injector: Injector) {
    super(ngControl, injector);
    this.configurator = new UserDropdownComponentConfigurator();
  }
}
