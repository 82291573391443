<div class="page">
    <div class="page__nav">
      <app-custom-nav>
        <kendo-button themeColor="primary" [routerLink]="['/app']" icon="login">
          Login
        </kendo-button>
        <kendo-button [routerLink]="['/external/register']" icon="edit">
          Register
        </kendo-button>
      </app-custom-nav>
    </div>
    <div class="page__header">
      <img class="page__header-image" src="/assets/images/banner-footer-image.png">
      <div class="page__header-content">
        <h1>Coach Index</h1>
      </div>
      <div class="page__header-overlay"></div>
    </div>
  <main>
    <p class="main-description u-text-center">The Coach Index is an equality, diversity and inclusion initiative Aiming to increase the representation of individuals from black, Asian  and mixed heritage backgrounds and all women in English professional football's coaching workforce. This initiative further supports the Premier League’s <a href="https://www.premierleague.com/NoRoomForRacism">No Room For Racism Action Plan</a> and The FA's <a href="https://www.thefa.com/news/2020/oct/27/football-leadership-diversity-code-launched-271020">Football Leadership Diversity Code</a>.</p>

    <div class="grid--boxes">
      <div class="grid__box">
          <div class="box">
              <div class="box__image">
                <span class="material-symbols-outlined">people</span>
              </div>
              <div class="box__content">
                  <p>Register your details and create a profile</p>
              </div>
          </div>
      </div>
      <div class="grid__box">
          <div class="box">
              <div class="box__image">
                <span class="material-symbols-outlined">sports_soccer</span>
              </div>
              <div class="box__content">
                  <p>Clubs will view your profile and share job opportunities and alerts</p>
              </div>
          </div>
      </div>
      <div class="grid__box">
          <div class="box">
              <div class="box__image">
                <span class="material-symbols-outlined">person_add</span>
              </div>
              <div class="box__content">
                  <p>You will be signposted to training and development opportunities</p>
              </div>
          </div>
      </div>
    </div>

    <div class="grid--cards">
      <div class="card">
        <span class="card__image">
          <img src="/assets/images/graphic/logo.png" />
        </span>
        <span class="card__content">
          <h3 class="card__content-title">Coach Index to create diverse coaching workforce</h3>
          <button class="card__content-button">
            <span>www.premierleague.com</span>
            <span class="material-symbols-outlined">keyboard_arrow_right</span>
          </button>
        </span>
      </div>
      <div class="card">
          <span class="card__image">
            <img src="/assets/images/graphic/logo.png" />
          </span>
          <span class="card__content">
            <h3 class="card__content-title">Nevin: Coach Index can improve representation at all levels</h3>
            <button class="card__content-button">
              <span>www.premierleague.com</span>
              <span class="material-symbols-outlined">keyboard_arrow_right</span>
            </button>
          </span>
      </div>
    </div>
  </main>
</div>

<app-footer></app-footer>
