import { Injectable } from "@angular/core"
import { DisplayDataResolver } from "@pl/pl-lib/common"
import { isEmpty } from "lodash"
import { User } from "../../models/user"

@Injectable({
  providedIn: "root",
})
export class UserDisplayDataResolver implements DisplayDataResolver<User> {
  typeDisplayName = "User"

  constructor() {}

  getIcon(entity: User): string | undefined {
    return "k-i-inherited"
  }

  getImage(entity: User): string | undefined {
    return undefined
  }

  getSubTitle(entity: User): string | undefined {
    return undefined
  }

  getTitle(entity: User): string {
    return entity.name
  }

  getShortTitle(entity: User): string {
    return entity.id
  }

  getItemHref(entityOrID: User | string): string | undefined {
    if (isEmpty(entityOrID)) {
      return "/app/user"
    }
    if (typeof entityOrID === "string") return "/app/user/" + entityOrID
    return "/app/user/" + entityOrID.id
  }
}
