<kendo-expansionpanel [(expanded)]="isExpanded" *hasPermission="'MediaHub.Audit.History';action:'Read'">
  <ng-template kendoExpansionPanelTitleDirective>
    <div class="header">
      <span>{{ title }}</span>
    </div>
  </ng-template>
  <div class="content">
    <pl-loader *ngIf="loading"></pl-loader>
    <kendo-expansionpanel *ngFor="let item of items" [expanded]="false">
      <ng-template kendoExpansionPanelTitleDirective>
        <div>{{item.showTitle}}</div>
        <div class="title-details">
          <div class="user">by {{item.changeUser.name || item.changeUserId}}</div>
          <div class="date">at {{item.changeDate | kendoDate:'F'}}</div>
          <div class="short-date">at {{item.changeDate | kendoDate:'G'}}</div>
        </div>
      </ng-template>
      <ng-container>
        <div class="history-item-details">
<!--          <ng-container *ngIf="item.type.startsWith('link-')">-->
<!--            <app-linked-record-panel [entity]="item.entity" [contextObjectId]="id"-->
<!--                                     [showDeleteButton]="false"></app-linked-record-panel>-->
<!--          </ng-container>-->
          <ng-container *ngIf="item.type.startsWith('custom-')">

          </ng-container>
          <ng-container *ngIf="item.type.startsWith('child-')">
            <pl-entity-panel *ngIf="item.entity" [entity]="item.entity"
                              [showDeleteButton]="false"></pl-entity-panel>
          </ng-container>
          <ng-container *ngIf="item.type.startsWith('m2m-')">
            <pl-entity-panel *ngIf="item.entity && item.childEntityProperty" [entity]="item.entity[item.childEntityProperty]"
                              [showDeleteButton]="false"></pl-entity-panel>
          </ng-container>
          <div class="changes"
               *ngIf="(item.isEntityCrud || item.type.startsWith('custom-')) && item.changedProperties && item.changedProperties.length>0">
            <div class="label">Changes</div>
            <table>
              <thead>
              <tr>
                <td>Property</td>
                <td *ngIf="item.type!=='create'">From</td>
                <td>To</td>
              </tr>
              </thead>
              <tbody>
              <tr *ngFor="let change of item.changedProperties">
                <td>{{change.name}}</td>
                <td *ngIf="item.type!=='create'">{{change.from | stripHTML}}</td>
                <td>{{change.to | stripHTML | translate}}</td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
      </ng-container>
    </kendo-expansionpanel>
  </div>

</kendo-expansionpanel>
