import { BaseDropdownComponentConfigurator } from '@pl/pl-lib/forms';
import { CountryDisplayDataResolver } from 'src/app/core/resolvers/displayData/country';
import { CountryService } from 'src/app/core/services/country';

export class CountryDropdownComponentConfigurator extends BaseDropdownComponentConfigurator {
  constructor() {
    super();
    this.dataServiceType = CountryService
    this.displayDataResolverType = CountryDisplayDataResolver
  }
}
