import {
    Component,
    EventEmitter,
    Output,
    ViewEncapsulation
  } from '@angular/core';
  import { FileRestrictions, SelectEvent } from '@progress/kendo-angular-upload';
  
  @Component({
    selector: 'app-uploader',
    templateUrl: './uploader.component.html',
    styleUrls: ['./uploader.component.scss'],
    styles: [
      `
        .k-upload .k-upload-files .k-file-multiple,
        .k-upload .k-upload-files .k-file-single {
          display: flex;
        }
  
        .k-upload[dir='rtl'] .k-file-name-size-wrapper,
        .k-rtl .k-upload .k-file-name-size-wrapper {
          display: flex;
          flex-direction: column;
        }
  
        .k-upload .k-upload-files .k-file-size {
          align-self: flex-start;
        }
      `
    ],
    encapsulation: ViewEncapsulation.None
  })
  export class UploaderComponent {
    @Output() imageUploadedEvent = new EventEmitter<
      string | ArrayBuffer | null
    >();
    public imagePreview: any;
  
    imageRestrictions: FileRestrictions = {
      allowedExtensions: ['.jpg', '.png']
    };
  
    constructor() {}
  
    public select(e: SelectEvent): void {
      const that = this;
      const fileNames = [];
  
      e.files.forEach((file) => {
        fileNames.push(file.name);
  
        if (!file.validationErrors && file.rawFile != null) {
          const reader = new FileReader();
  
          reader.onload = function (ev) {
            if (ev.target != null) {
              const image = {
                src: ev.target['result'],
                uid: file.uid
              };
              that.imageUploadedEvent.emit(image.src);
              that.imagePreview = image;
            }
          };
  
          reader.readAsDataURL(file.rawFile);
        }
      });
    }
  
    public clear(): void {
      this.imageUploadedEvent.emit(null);
      this.imagePreview = null;
    }
  }
  