import { Type } from "@angular/core"
import { DisplayDataResolver } from "@pl/pl-lib/common"

export const DisplayDataResolversMap: {
  [i: string]: Type<DisplayDataResolver<any>>
} = {}
import { AppSettingDisplayDataResolver } from "../../core/resolvers/displayData/appsetting"
import { UserDisplayDataResolver } from "../../core/resolvers/displayData/user"
import { OrganisationDisplayDataResolver } from "../../core/resolvers/displayData/organisation"
import { RegionDisplayDataResolver } from "../../core/resolvers/displayData/region"
import { CoachDisplayDataResolver } from "../../core/resolvers/displayData/coach"
import { CoachExperienceDisplayDataResolver } from "../../core/resolvers/displayData/coachExperience"
import { CoachEducationDisplayDataResolver } from "../../core/resolvers/displayData/coachEducation"
import { DepartmentDisplayDataResolver } from "../../core/resolvers/displayData/department"
import { CourseDisplayDataResolver } from "../../core/resolvers/displayData/course"
import { CountryDisplayDataResolver } from "../../core/resolvers/displayData/country"
import { ClubDisplayDataResolver } from "../../core/resolvers/displayData/club"
import { JobDisplayDataResolver } from "../../core/resolvers/displayData/job"
import { JobShortlistDisplayDataResolver } from "../../core/resolvers/displayData/jobshortlist"
import { EventDisplayDataResolver } from "../../core/resolvers/displayData/event"
import { CoachDesiredRegionDisplayDataResolver } from "src/app/core/resolvers/displayData/coachDesiredRegion"
import { CoachQualificationDisplayDataResolver } from "src/app/core/resolvers/displayData/coachQualification"
import { CoachDesiredDepartmentDisplayDataResolver } from "src/app/core/resolvers/displayData/coachDesiredDepartment"

DisplayDataResolversMap["AppSetting"] = AppSettingDisplayDataResolver
DisplayDataResolversMap["User"] = UserDisplayDataResolver
DisplayDataResolversMap["Organisation"] = OrganisationDisplayDataResolver
DisplayDataResolversMap["Region"] = RegionDisplayDataResolver
DisplayDataResolversMap["Coach"] = CoachDisplayDataResolver
DisplayDataResolversMap["CoachExperience"] = CoachExperienceDisplayDataResolver
DisplayDataResolversMap["CoachEducation"] = CoachEducationDisplayDataResolver
DisplayDataResolversMap["CoachQualification"] =
  CoachQualificationDisplayDataResolver
DisplayDataResolversMap["CoachDesiredDepartment"] =
  CoachDesiredDepartmentDisplayDataResolver
DisplayDataResolversMap["CoachDesiredRegion"] =
  CoachDesiredRegionDisplayDataResolver
DisplayDataResolversMap["Department"] = DepartmentDisplayDataResolver
DisplayDataResolversMap["Course"] = CourseDisplayDataResolver
DisplayDataResolversMap["Country"] = CountryDisplayDataResolver
DisplayDataResolversMap["Club"] = ClubDisplayDataResolver
DisplayDataResolversMap["Job"] = JobDisplayDataResolver
DisplayDataResolversMap["JobShortlist"] = JobShortlistDisplayDataResolver
DisplayDataResolversMap["Event"] = EventDisplayDataResolver
