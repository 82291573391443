<ng-container contentTop>
  <div class="content-header-wrapper">
    <pl-lib-header-content-layout
      [title]="title"
      [subTitle]="subTitle"
      [actions]="actions"
    ></pl-lib-header-content-layout>
  </div>
</ng-container>
<div class="content">
 <pl-entity-list [configurator]="listConfigurator"></pl-entity-list>
</div>
