import {APP_INITIALIZER, Injector} from '@angular/core';
import { OAuthService } from 'angular-oauth2-oidc';
import { catchError, firstValueFrom } from 'rxjs';
import {
  appInjector, AppTitleService,
  BaseApiPermissionsService,
  BaseEnvironmentService,
  PermissionManagerConfig,
  PermissionsService
} from '@pl/pl-lib/common';
import { TranslateService } from '@ngx-translate/core';
import {IHydrateArgs} from "@pl/permission-manager";
import { ErrorsService } from '@pl/pl-lib/errors';
import { EnvironmentService } from './core/services/environment';
import { AppSettingService } from './core/services/appsetting';

export const appInitialiserFactory = (
  environmentService: EnvironmentService,
  oAuthService: OAuthService,
  permissionsService: PermissionsService,
  sgPermissionsService: BaseApiPermissionsService,
  appSettingService: AppSettingService,
  permissionManagerConfig: PermissionManagerConfig,
  translateService: TranslateService,
  injector: Injector,
  appTitleService: AppTitleService,
  errorService: ErrorsService
) => {
  return async () => {
    appInjector(injector);
    translateService.setDefaultLang('en');

    if (!await firstValueFrom(translateService.use('en').pipe(catchError(errorService.handleObservableCatchError('i18n', 'en', 'Error while initialising translation service.'))))) {
      return;
    }

    if (!await environmentService.loadAndHandleErrors()) {
      return;
    }


    // Environment
    await firstValueFrom(environmentService.load());
    if (!environmentService.authConfig) {
      throw new Error('Error while retrieving config');
    }
    
    permissionManagerConfig.placUrl =
      permissionManagerConfig.placUrl || environmentService.placApiHostUrl;
    permissionManagerConfig.placApplicationId =
      permissionManagerConfig.placApplicationId ||
      environmentService.placApplicationId;
    permissionManagerConfig.placApplicationName =
      permissionManagerConfig.placApplicationName ||
      environmentService.placApplicationName;
    permissionManagerConfig.ttl = 900000;

    permissionManagerConfig.dynamicResolversGetter = {
      get: (name) => {
        return Promise.resolve({
          resolve: (attributeValue: string, parameters: string) =>
            firstValueFrom(sgPermissionsService.getDynamicResolverValue(name))
        });
      }
    };
    permissionManagerConfig.missingIncludesHydraterGetter= {
        hydrate: async (args: IHydrateArgs) =>{
          return firstValueFrom(sgPermissionsService.hydrate(args))
      },
    };
    permissionManagerConfig.setSubjectNameToDbModelMapFunction = () => {
      return firstValueFrom(sgPermissionsService.getSubjectToEntityTypeMap());
    };

    // AuthService
    oAuthService.configure(environmentService.authConfig);
    oAuthService.setupAutomaticSilentRefresh();

    await oAuthService.loadDiscoveryDocumentAndTryLogin();
    if (oAuthService.getAccessToken()) {
      await permissionsService.init();
    }
    oAuthService.events.subscribe(async (_) => {
      if (_.type === 'token_received') {
        await oAuthService.loadUserProfile();
        await permissionsService.init();
      }
      if (
        _.type === 'discovery_document_loaded' &&
        oAuthService.getAccessToken()
      ) {
        await permissionsService.init();
      }
    });

    await firstValueFrom(appSettingService.load());
    if (!appSettingService.isLoaded) {
      throw new Error('Error while retrieving appSettings');
    }
    appTitleService.init();
    return true;
  };
};

export const AppInitialiser = {
  provide: APP_INITIALIZER,
  useFactory: appInitialiserFactory,
  deps: [
    BaseEnvironmentService,
    OAuthService,
    PermissionsService,
    BaseApiPermissionsService,
    AppSettingService,
    PermissionManagerConfig,
    TranslateService,
    Injector,
    AppTitleService,
    ErrorsService
  ],
  multi: true
};