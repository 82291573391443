import { Injectable } from "@angular/core"
import { HttpClient } from "@angular/common/http"
import {
  BaseCacheableApiService,
  BaseEnvironmentService,
} from "@pl/pl-lib/common"

import { Organisation } from "../models/organisation"
import { CreateOrganisation } from "../models/dtos/organisation/organisation.create-dto"
import { UpdateOrganisation } from "../models/dtos/organisation/organisation.update-dto"

@Injectable({
  providedIn: "root",
})
export class OrganisationService extends BaseCacheableApiService<
  Organisation,
  CreateOrganisation,
  UpdateOrganisation
> {
  constructor(http: HttpClient, environmentService: BaseEnvironmentService) {
    super("organisations", http, environmentService, ["providerId"])
  }
}
