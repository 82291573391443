<kendo-scheduler
  [kendoSchedulerBinding]="events"
  [selectedDate]="selectedDate"
  (eventClick)="onEventClick.emit($event)"
  startTime="07:00"
  endTime="22:00"
>
  <ng-template kendoSchedulerEventTemplate let-event="event">
    <div class="flex flex-col">
      <p>{{ event.title }}:</p>
      <p>{{ event.description }}</p>
    </div>
  </ng-template>
<!--  <kendo-scheduler-week-view></kendo-scheduler-week-view>-->
  <kendo-scheduler-month-view [eventHeight]="40"></kendo-scheduler-month-view>
</kendo-scheduler>
