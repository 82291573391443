<pl-lib-login-page backgroundImage="assets/images/template-background-1.png">
  <div left class="summary">
    <img src="assets/images/pl_logo.png" alt="">
  </div>
  <ng-container right>
    <div class="k-card">
      <div class="k-card-header">
        <h5 class="k-card-title">Sign in</h5>
        <h6 class="k-card-subtitle">Coach Index</h6>
      </div>
      <div class="k-card-body" [innerHtml]="text$ | async"></div>
      <div class="k-card-footer">
        <button kendoButton rounded="full" fillMode="outline" themeColor="primary" size="large" (click)="onLogin()">
          <img class="microsoftLogo" src="/assets/images/microsoft-logo.webp" /> Continue via The Club Portal
        </button>
      </div>
    </div>
  </ng-container>
</pl-lib-login-page>
