import { Component, Input, OnInit } from '@angular/core';
import { IFormItem } from '../interfaces';
import { AbstractControl, FormControl } from '@angular/forms';

@Component({
  selector: 'app-ci-form-field',
  templateUrl: './ci-form-field.component.html',
  styleUrls: ['./ci-form-field.component.scss']
})
export class CiFormFieldComponent implements OnInit {
  @Input() formItem: IFormItem;
  @Input() control: AbstractControl | null;
  @Input() hideButton = false;
  public formControl: FormControl;

  constructor() { }

  public ngOnInit(): void {
    this.formControl = this.control as FormControl;

    if(!this.formItem.toggled) this.formItem.toggled = false;
    if(!this.formItem.isHidden) this.formItem.isHidden = false;
  }

  public toggle(): void {
    this.formItem.toggled = !(this.formItem.toggled);
  }

}
