import { Component, Input, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FeedbackMessage } from "@pl/pl-lib/common";
import { PlEntityListSectionConfigurator } from "./pl-entity-list-section.configurator";
import { PlEntityListComponent } from "../pl-entity-list/pl-entity-list.component";
import { Observable, of, ReplaySubject, switchMap } from "rxjs";
import { ChipThemeColor } from "@progress/kendo-angular-buttons";

export type ChipSettings = {
  theme: ChipThemeColor;
  content?: string;
}

@Component({
  selector: 'pl-related-entities-section-component',
  templateUrl: './pl-entity-list-section.component.html',
  styleUrls: ['./pl-entity-list-section.component.scss']
})
export class PlEntityListSectionComponent implements OnInit {
  private _grid: PlEntityListComponent<any>;
  private grid$ = new ReplaySubject<PlEntityListComponent<any>>(1)
  @ViewChild('grid')
  set grid(v: PlEntityListComponent<any>) {
    this._grid = v;
    this.grid$.next(v);
  }

  @Input()
  public isExpanded: boolean;
  @Input()
  public icon?: string;
  @Input()
  public refreshTrigger: Observable<any>;
  @Input()
  headerTemplate: TemplateRef<any>;
  @Input()
  bodyTemplate: TemplateRef<any>;
  @Input()
  actionsTemplate: TemplateRef<any>;
  @Input()
  title: string;
  @Input()
  errors: FeedbackMessage[];
  @Input()
  chipSettings?: ChipSettings;

  public configurator: PlEntityListSectionConfigurator<any>;
  public itemsGetter: () => Observable<any>;
  public itemsCounterConfig: {
    type: 'default' | 'custom',
    getter?: () => Observable<number>;
    run?: 'onceBeforeItems' | 'always';
  } = {
      type: 'default',
    }
  public lazyLoad: boolean;

  constructor() { }

  ngOnInit() {
    this.title = this.title || this.configurator.title;
    this.icon = this.icon || this.configurator.icon;
    this.isExpanded = this.isExpanded || this.configurator.expanded;
    this.lazyLoad = !this.configurator.gridConfigurator.loadOnInit;
    this.chipSettings = this.chipSettings || this.configurator.chipSettings;
    this.itemsGetter = () => {
      if (!this.configurator.gridConfigurator.render) return of(undefined);
      return this._grid ? this._grid.fetch() : this.grid$.pipe(switchMap(g => g.fetch()));
    }
    this.itemsCounterConfig = {
      ...this.itemsCounterConfig,
      type: 'custom',
      getter: () => {
        if (!this.configurator.gridConfigurator.render) return of(0);
        return this._grid ? this._grid.count() : this.grid$.pipe(switchMap(g => g.count()))
      }
    };
    this.errors = this.configurator.gridConfigurator.defaultFeedback ?? [];
  }

}
