<kendo-chip
  [size]="size"
  [themeColor]="chipThemeColor">
  <ng-container *ngIf="loading">
    <pl-loader></pl-loader>
  </ng-container>
  <ng-container *ngIf="!loading">
    {{chipContent}}
  </ng-container>
</kendo-chip>
<span>{{ label }}</span>
