import { Component, Injector } from '@angular/core';
import { NgControl } from '@angular/forms';

import { DepartmentDropdownComponentConfigurator } from './department-dropdown-component.configurator';
import { BaseMultiselectDropdownComponent } from '@pl/pl-lib/forms';
import { Department } from 'src/app/core/models/department';

@Component({
  selector: 'app-department-multiselect-dropdown',
  templateUrl:
    '../../../../../../node_modules/@pl/pl-lib/assets/templates/searchable-dropdown/multiselect-dropdown.component.html',
  styleUrls: [
    '../../../../plLibSetup/components/searchable-dropdown/multiselect-dropdown.component.scss'
  ]
})
export class DepartmentMultiselectDropdownComponent extends BaseMultiselectDropdownComponent<Department> {
  constructor(ngControl: NgControl, injector: Injector) {
    super(ngControl, injector);
    this.configurator = new DepartmentDropdownComponentConfigurator();
  }
}
