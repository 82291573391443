import { Injectable } from "@angular/core"
import { DisplayDataResolver } from "@pl/pl-lib/common"
import { isEmpty } from "lodash"
import { CoachDesiredDepartment } from "../../models/coachdesireddepartment"

@Injectable({
  providedIn: "root",
})
export class CoachDesiredDepartmentDisplayDataResolver
  implements DisplayDataResolver<CoachDesiredDepartment>
{
  typeDisplayName = "CoachDesiredDepartment"

  constructor() {}

  getIcon(entity: CoachDesiredDepartment): string | undefined {
    return "k-i-inherited"
  }

  getImage(entity: CoachDesiredDepartment): string | undefined {
    return undefined
  }

  getSubTitle(entity: CoachDesiredDepartment): string | undefined {
    return undefined
  }

  getTitle(entity: CoachDesiredDepartment): string {
    return entity.department.name
  }

  getShortTitle(entity: CoachDesiredDepartment): string {
    return entity.id
  }

  getItemHref(entityOrID: CoachDesiredDepartment | string): string | undefined {
    if (isEmpty(entityOrID)) {
      return "/app/coachDesiredDepartment"
    }
    if (typeof entityOrID === "string") return "/app/coachDesiredDepartment/" + entityOrID
    return "/app/coachDesiredDepartment/" + entityOrID.id
  }
}
