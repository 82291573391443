import { Injectable } from "@angular/core"
import { HttpClient } from "@angular/common/http"
import {
  BaseCacheableApiService,
  BaseEnvironmentService,
} from "@pl/pl-lib/common"

import { Coach } from "../models/coach"
import { CreateCoach } from "../models/dtos/coach/coach.create-dto"
import { UpdateCoach } from "../models/dtos/coach/coach.update-dto"
import { CoachQualification } from "../models/coachqualification"

@Injectable({
  providedIn: "root",
})
export class CoachService extends BaseCacheableApiService<
  Coach,
  CreateCoach,
  UpdateCoach
> {
  constructor(http: HttpClient, environmentService: BaseEnvironmentService) {
    super("coaches", http, environmentService, ["providerId"])
  }

  public approveCoach(coachId: string) {    
    return this.http.post(`${this.environmentService.apiHostUrl}/coaches/approve`, { id: coachId });
  }

  override convert(input:Coach):Coach{
    const highestCoreQualification = input.coachQualifications.filter((qualification) => {
      return (qualification.course.core)
    }).sort((a, b) => a.course.order - b.course.order)[0];
    const desiredRegions = input.coachDesiredRegions?.map(desiredRegion => `${desiredRegion.region.name}`) ?? [];
    const desiredDepartments = input.coachDesiredDepartments?.map(desiredDepartment => `${desiredDepartment.department.name}`) ?? []

    const output = {
      ...input,
      desiredRegions: desiredRegions,
      desiredDepartments: desiredDepartments,
      highestCoreQualification: highestCoreQualification.course.name,
      displayNumber: `COACH${input.number.toString().padStart(5, '0')}`
    }
    return output;
  }
}
