import { Inject, Injectable, Optional } from '@angular/core';
import { BaseEnvironmentService, WINDOW } from '@pl/pl-lib/common';
import { HttpClient } from '@angular/common/http';
import { ErrorsService } from '@pl/pl-lib/errors';
import { IEnvironment } from '../models/environment';

@Injectable({
  providedIn: 'root'
})
export class EnvironmentService extends BaseEnvironmentService implements IEnvironment {
  public registrationLogicAppUrl: string;

  constructor(httpClient: HttpClient,
              @Inject(WINDOW) window: Window,
              @Optional() errorService?:ErrorsService
  ) {
    super(httpClient, window, errorService);
  }

  protected override setConfig(c: IEnvironment) {
    super.setConfig(c);
    this.registrationLogicAppUrl = c.registrationLogicAppUrl;
  }
}
