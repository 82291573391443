import { NgModule } from '@angular/core';
import { CommonModule as AngularCommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

import { TranslateModule } from '@ngx-translate/core';

import { CommonModule } from '@pl/pl-lib/common';
import { NavigationModule } from '@pl/pl-lib/navigation';
import { TemplatePagesModule } from '@pl/pl-lib/template-pages';

import { ButtonsModule } from '@progress/kendo-angular-buttons';
import { LayoutModule } from '@progress/kendo-angular-layout';
import { PopupModule } from '@progress/kendo-angular-popup';
import { LabelModule } from '@progress/kendo-angular-label';
import { InputsModule } from '@progress/kendo-angular-inputs';
import { GridModule } from '@progress/kendo-angular-grid';
import { IconModule } from '@progress/kendo-angular-icons';
import { DateInputsModule } from '@progress/kendo-angular-dateinputs';
import { NotificationModule } from '@progress/kendo-angular-notification';
import { DropDownsModule } from '@progress/kendo-angular-dropdowns';
import {
  IndicatorsModule,
  LoaderModule
} from '@progress/kendo-angular-indicators';
import { EditorModule } from '@progress/kendo-angular-editor';
import { TooltipsModule } from '@progress/kendo-angular-tooltip';
import { DialogsModule } from '@progress/kendo-angular-dialog';
import { MenusModule } from '@progress/kendo-angular-menu';

import { IntlModule } from '@progress/kendo-angular-intl';
import { SchedulerModule } from '@progress/kendo-angular-scheduler';

import { ProfileButtonComponent } from './components/profile-button/profile-button.component';
import { QuickLinksComponent } from './components/quick-links/quick-links.component';
import { DataGridComponent } from './components/data-grid/data-grid.component';
import { BaseHistorySectionComponent } from './components/history-section/base-history-section.component';
import { ExistingEntityComponent } from './components/existing-entity/existing-entity.component';
import { UserDropdownFilterComponent } from './components/data-grid/custom-filters/user-dropdown-filter/user-dropdown-filter.component';

import { CoreModule } from '../core/core.module';
import { ChartsModule } from '@progress/kendo-angular-charts';
import { FileSelectModule, UploadModule } from '@progress/kendo-angular-upload';
import { DataGridsModule } from '@pl/pl-lib/data-grids';
import { FormsModule as PLFormsModule } from '@pl/pl-lib/forms';
import { OrganisationDropdownFilterComponent } from './components/data-grid/custom-filters/organisation-dropdown-filter/organisation-dropdown-filter.component';
import { PlEntityListComponent } from './components/pl-entity-list/pl-entity-list.component';
import { AppEntityDetailComponent } from './components/app-entity-detail/app-entity-detail.component';
import { PlEntityListSectionComponent } from './components/pl-entity-list-section/pl-entity-list-section.component';
import { PlEntityListPageComponent } from './components/pl-entity-list-page/pl-entity-list-page.component';
import { PlStepperButtonsComponent } from './components/pl-stepper-page/pl-stepper-buttons.component';
import { FilterModule } from '@progress/kendo-angular-filter';
import { PlDynamicComponent } from './components/pl-entity-custom-component/pl-entity-custom-component.component';
import { LabelChipComponent } from './components/label-badge/label-chip.component';
import { CustomNavComponent } from './components/custom-nav/custom-nav.component';
import { DashboardCardComponent } from './components/dashboard-card/dashboard-card.component';
import { CalendarComponent } from './components/calendar/calendar.component';

import { CoachStateDropdownListComponent } from './components/dropdown-list/coach-state-dropdown-list/coach-state-dropdown-list.component';
import { CoachGenderDropdownListComponent } from './components/dropdown-list/coach-gender-dropdown-list/coach-gender-dropdown-list.component';
import { CoachIdentityDropdownListComponent } from './components/dropdown-list/coach-identity-dropdown-list/coach-identity-dropdown-list.component';
import { CoachEducationLevelDropdownListComponent } from './components/dropdown-list/coach-education-level-dropdown-list/coach-education-level-dropdown-list.component';
import { CiFormFieldComponent } from '../features/register/ci-form-field/ci-form-field.component';
import { JobShortlistStateDropdownListComponent } from './components/dropdown-list/jobshortlist-state-dropdown-list/jobshortlist-state-dropdown-list.component';
import { FooterComponent } from './components/footer/footer.component';
import { UserSearchableDropdownComponent } from './components/searchable-dropdown/user-searchable-dropdown/user-searchable-dropdown.component';
import { UserMultiselectDropdownComponent } from './components/searchable-dropdown/user-searchable-dropdown/user-multiselect-dropdown.component';
import { OrganisationMultiselectDropdownComponent } from './components/searchable-dropdown/organisation-searchable-dropdown/organisation-multiselect-dropdown.component';
import { OrganisationSearchableDropdownComponent } from './components/searchable-dropdown/organisation-searchable-dropdown/organisation-searchable-dropdown.component';
import { CountryMultiselectDropdownComponent } from './components/searchable-dropdown/country-searchable-dropdown/country-multiselect-dropdown.component';
import { CountrySearchableDropdownComponent } from './components/searchable-dropdown/country-searchable-dropdown/country-searchable-dropdown.component';
import { CourseSearchableDropdownComponent } from './components/searchable-dropdown/course-searchable-dropdown/course-searchable-dropdown.component';
import { CourseMultiselectDropdownComponent } from './components/searchable-dropdown/course-searchable-dropdown/course-multiselect-dropdown.component';
import { JobMultiselectDropdownComponent } from './components/searchable-dropdown/job-searchable-dropdown/job-multiselect-dropdown.component';
import { CoachMultiselectDropdownComponent } from './components/searchable-dropdown/coach-searchable-dropdown/coach-multiselect-dropdown.component';
import { CoachSearchableDropdownComponent } from './components/searchable-dropdown/coach-searchable-dropdown/coach-searchable-dropdown.component';
import { JobSearchableDropdownComponent } from './components/searchable-dropdown/job-searchable-dropdown/job-searchable-dropdown.component';
import { RegionSearchableDropdownComponent } from './components/searchable-dropdown/region-searchable-dropdown/region-searchable-dropdown.component';
import { RegionMultiselectDropdownComponent } from './components/searchable-dropdown/region-searchable-dropdown/region-multiselect-dropdown.component';
import { DepartmentSearchableDropdownComponent } from './components/searchable-dropdown/department-searchable-dropdown/department-searchable-dropdown.component';
import { DepartmentMultiselectDropdownComponent } from './components/searchable-dropdown/department-searchable-dropdown/department-multiselect-dropdown.component';
import { CustomLoadingComponent } from './components/loading/loading.component';
import { UploaderComponent } from './components/uploader/uploader.component';
import { LogoUploadComponent } from './components/logo-upload/logo-upload.component';
import { TreeListsModule } from '@pl/pl-lib/tree-lists';

@NgModule({
  declarations: [
    ProfileButtonComponent,
    DataGridComponent,
    ExistingEntityComponent,
    BaseHistorySectionComponent,
    /* Dropdowns */
    UserSearchableDropdownComponent,
    UserMultiselectDropdownComponent,
    OrganisationMultiselectDropdownComponent,
    OrganisationSearchableDropdownComponent,
    CountryMultiselectDropdownComponent,
    CountrySearchableDropdownComponent,
    CourseSearchableDropdownComponent,
    CourseMultiselectDropdownComponent,
    JobMultiselectDropdownComponent,
    CoachMultiselectDropdownComponent,
    CoachSearchableDropdownComponent,
    JobSearchableDropdownComponent,
    RegionSearchableDropdownComponent,
    RegionMultiselectDropdownComponent,
    DepartmentSearchableDropdownComponent,
    DepartmentMultiselectDropdownComponent,
    CoachStateDropdownListComponent,
    CoachGenderDropdownListComponent,
    CoachIdentityDropdownListComponent,
    CoachEducationLevelDropdownListComponent,
    JobShortlistStateDropdownListComponent,
    UserDropdownFilterComponent,
    OrganisationDropdownFilterComponent,
    PlEntityListComponent,
    AppEntityDetailComponent,
    PlEntityListSectionComponent,
    PlEntityListPageComponent,
    PlStepperButtonsComponent,
    PlDynamicComponent,
    QuickLinksComponent,
    LabelChipComponent,
    CustomNavComponent,
    DashboardCardComponent,
    CalendarComponent,
    CiFormFieldComponent,
    FooterComponent,
    CustomLoadingComponent,
    UploaderComponent,
    LogoUploadComponent
  ],
  imports: [
    AngularCommonModule,
    TranslateModule.forChild(),
    RouterModule,
    TooltipsModule,
    ButtonsModule,
    NavigationModule,
    PopupModule,
    LayoutModule,
    CoreModule,
    LabelModule,
    FormsModule,
    ReactiveFormsModule,
    PLFormsModule,
    InputsModule,
    CommonModule,
    GridModule,
    NotificationModule,
    IndicatorsModule,
    IconModule,
    DateInputsModule,
    DropDownsModule,
    TemplatePagesModule,
    DialogsModule,
    EditorModule,
    MenusModule,
    LoaderModule,
    IntlModule,
    ChartsModule,
    UploadModule,
    FileSelectModule,
    DataGridsModule,
    FilterModule,
    SchedulerModule,
    TreeListsModule
  ],
  exports: [
    TranslateModule,
    ButtonsModule,
    NavigationModule,
    PopupModule,
    LayoutModule,
    CoreModule,
    LabelModule,
    FormsModule,
    ReactiveFormsModule,
    PLFormsModule,
    InputsModule,
    ProfileButtonComponent,
    QuickLinksComponent,
    CommonModule,
    GridModule,
    NotificationModule,
    IndicatorsModule,
    DataGridComponent,
    IconModule,
    DateInputsModule,
    DropDownsModule,
    EditorModule,
    UserSearchableDropdownComponent,
    UserMultiselectDropdownComponent,
    MenusModule,
    LoaderModule,
    BaseHistorySectionComponent,
    ExistingEntityComponent,
    BaseHistorySectionComponent,
    /*Dropdowns*/
    UserSearchableDropdownComponent,
    UserMultiselectDropdownComponent,
    UserDropdownFilterComponent,
    OrganisationDropdownFilterComponent,
    OrganisationMultiselectDropdownComponent,
    OrganisationSearchableDropdownComponent,
    IntlModule,
    ChartsModule,
    PlEntityListComponent,
    PlEntityListPageComponent,
    JobMultiselectDropdownComponent,
    CoachMultiselectDropdownComponent,
    CoachSearchableDropdownComponent,
    JobSearchableDropdownComponent,
    AppEntityDetailComponent,
    PlStepperButtonsComponent,
    PlDynamicComponent,
    LabelChipComponent,
    CustomNavComponent,
    DashboardCardComponent,
    CalendarComponent,
    CiFormFieldComponent,
    FooterComponent,
    CustomLoadingComponent,
    UploadModule,
    UploaderComponent,
    LogoUploadComponent
  ],
  providers: []
})
export class SharedModule {}
