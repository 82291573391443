<a [href]="link" target="_blank" class="card">
    <span class="card__image" style="background-image: url('{{image}}')"></span>
    <span class="card__content">
      <h3 class="card__content-title">{{title}}</h3>
      <p class="card__content-description">{{description}}</p>
      <kendo-button class="card__content-button">
        {{buttonText}}
      </kendo-button>
    </span>
</a>
