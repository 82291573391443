import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges
} from '@angular/core';
import { DropdownItem } from '@pl/pl-lib/forms';
import { UserService } from 'src/app/core/services/user';

@Component({
  selector: 'app-user-dropdown-filter',
  templateUrl: './user-dropdown-filter.component.html',
  styleUrls: ['./user-dropdown-filter.component.scss']
})
export class UserDropdownFilterComponent implements OnInit, OnChanges {
  @Input() value: number = 1;
  @Input() textField: string = 'text';
  @Input() valueField: string = 'value';
  @Output() valueChanged: EventEmitter<number> = new EventEmitter();

  public data: DropdownItem[];
  public currentValue: DropdownItem | undefined;

  constructor(private userService: UserService) {}

  ngOnInit(): void {
    this.userService.getAll().subscribe(
      (data) =>
        (this.data = data
          .sort((a, b) => a.name.localeCompare(b.name))
          .map(
            (user) =>
              ({
                text: user.name,
                value: user.name
              } as DropdownItem)
          ))
    );

    this.getCurrentValue(this.value);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['value'] && changes['value'].currentValue) {
      this.value = changes['value'].currentValue;
      this.getCurrentValue(this.value);
    }
  }

  private getCurrentValue(value: number): void {
    this.currentValue = this.data?.find((item) => item.value === value);
  }

  public onValueChange(value: DropdownItem): void {
    this.valueChanged.emit(value ? (value.value as number) : undefined);
  }
}
