import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { OAuthService } from 'angular-oauth2-oidc';
import { map, Observable, of } from 'rxjs';
import { AppSettingService } from 'src/app/core/services/appsetting';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  text$: Observable<string>;

  constructor(
    private oAuthService: OAuthService,
    private appSettingService: AppSettingService,
    private route: ActivatedRoute
  ) {
    this.route.queryParams.subscribe((params) => {
      if (params['skipLoginPage']) {
        this.onLogin();
      }
    });
  }

  ngOnInit(): void {
    this.text$ = of('Welcome');
    /*
    this.text$ = this.appSettingService.AppSetting$.pipe(
      map((s) => s.loginMessage.message)
    );*/
  }

  onLogin(): void {
    this.oAuthService.initImplicitFlow();
  }
}
