<div class="profile" *ngIf="coach">
  <div class="profile__banner" *ngIf="!isClub">
    <div class="profile__banner-content">
      <h3>
        <span>{{ coach.first_name }}</span>
        <strong>{{ coach.last_name }}</strong>
      </h3>
    </div>
  </div>
  <div class="profile__body">
    <div class="profile__body-header">
      <h4 class="profile__body-header-title">Personal details</h4>
      <div class="profile__body-header-actions">
        <button
          *ngIf="canApprove"
          kendoButton
          themeColor="primary"
          (click)="approveCoach()"
        >
          <span>Approve coach</span>
        </button>
        <button
          *ngIf="canEdit"
          kendoButton
          themeColor="base"
          [routerLink]="['/app/coach', this.id]"
        >
          <span>Edit profile</span>
        </button>
        <button *ngIf="canDelete" kendoButton (click)="deleteCoach()" themeColor="dark">
          <span>Delete profile</span>
        </button>
      </div>
    </div>

    <div class="box" *ngIf="!isClub">
      <div class="box__section">
        <div class="box__section-title">Ethnicity</div>
        <div class="box__section-value">{{ ethnic_identity }}</div>
      </div>
      <div class="box__section">
        <div class="box__section-title">Gender</div>
        <div class="box__section-value">{{ CoachGender[coach.gender] }}</div>
      </div>
      <div class="box__section">
        <div class="box__section-title">Date of birth</div>
        <div class="box__section-value">{{ coach.dob | date }}</div>
      </div>
      <div class="box__section">
        <div class="box__section-title">FA number</div>
        <div class="box__section-value">{{ coach.fa_number }}</div>
      </div>
    </div>

    <p class="" *ngIf="!isClub && highestCoreQualification">
        <b>Highest core qualification:</b> {{highestCoreQualification.course.name}}
    </p>

    <div class="profile__body-header">
      <h4 class="profile__body-header-title">Relevant experiences</h4>
    </div>

    <table>
      <tr>
        <th>Title</th>
        <th>Company/Club name</th>
        <th>Start date</th>
        <th>End date</th>
      </tr>
      <tr *ngFor="let experience of coach.coachExperiences">
        <td>{{ experience.role_title }}</td>
        <td>{{ experience.company_name }}</td>
        <td>{{ experience.start_date | date }}</td>
        <td>{{ experience.end_date | date }}</td>
      </tr>
    </table>

    <div class="profile__body-header">
      <h4 class="profile__body-header-title">Relevant education</h4>
    </div>

    <table>
      <tr>
        <th>Field</th>
        <th>Grade</th>
        <th>Start date</th>
        <th>End date</th>
      </tr>
      <tr *ngFor="let experience of coach.coachEducations">
        <td>{{ experience.field }}</td>
        <td>{{ experience.grade }}</td>
        <td>{{ experience.start_date | date }}</td>
        <td>{{ experience.end_date | date }}</td>
      </tr>
    </table>

    <div class="profile__body-header">
      <h4 class="profile__body-header-title">Relevant qualifications</h4>
    </div>

    <table>
      <tr>
        <th>Name</th>
        <th *ngIf="!isClub" style="width: 25.5rem">Completion country</th>
        <th *ngIf="!isClub" style="width: 26rem">Completion year</th>
      </tr>
      <tr *ngFor="let experience of coach.coachQualifications">
        <td s>{{ experience.course.name }}</td>
        <td *ngIf="!isClub">{{ experience.country.name }}</td>
        <td *ngIf="!isClub">{{ experience.completionYear }}</td>
      </tr>
    </table>

    <div class="profile__body-header">
      <h4 class="profile__body-header-title">Departments</h4>
    </div>

    <div>
      <kendo-chip
        *ngFor="let desiredDepartment of departments"
        [label]="desiredDepartment.department.name"
      >
      </kendo-chip>
    </div>

    <div class="profile__body-header">
      <h4 class="profile__body-header-title">Regions</h4>
    </div>

    <div>
      <kendo-chip
        *ngFor="let desiredRegion of regions"
        [label]="desiredRegion.region.name"
      >
      </kendo-chip>
    </div>
  </div>
</div>
