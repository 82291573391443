<pl-feedback-bar [messages]="messages"></pl-feedback-bar>
<app-data-grid
  #grid
  [tableId]="tableId"
  [data]="gridData"
  [paginated]="paginated"
  [isLoading]="isLoading"
  [state]="state"
  [selectable]="selectable"
  [filterable]="filtersSettings"
  [defaultFilterableValue]="{menu:true}"
  [actions]="actions"
  [secureGridHandler]="secureGridHandler.bind(this)"
  [selectionKeyGenerator]="selectionKeyGenerator.bind(this)"
  [editFormGetter]="editFormGetter.bind(this)"
  [customTemplateRef]="customToolbarTemplate"
  [customRowDetailsTemplate]="customRowDetailsTemplate"
  [rowClass]="configurator.rowClass"
  [isDetailExpanded]="configurator.isDetailExpanded"
  (cellClick)="cellClickHandler($event)"
  (dataStateChanged)="dataStateChangeHandler($event)"
  (addItemRequested)="handleAddItemRequest($event)"
  (deleteItemRequested)="handleDeleteItemRequest($event)"
  (editItemRequested)="handleEditItemRequest($event)"
  (editFinished)="handleEditFinished($event)"
  (editCanceled)="handleEditCanceled($event)"
  (customActionClicked)="customActionClicked($event)"
  [export]="export"
  [exportData]="$any(configurator.exportData)"
  [refresh]="refresh"
>
></app-data-grid>
