import { Component, Injector } from '@angular/core';
import { NgControl } from '@angular/forms';
import { CoachDropdownComponentConfigurator } from './coach-dropdown-component.configurator';
import { BaseComboboxDropdownComponent } from '@pl/pl-lib/forms';
import { Coach } from 'src/app/core/models/coach';

@Component({
  selector: 'app-coach-searchable-dropdown',
  templateUrl:
    '../../../../../../node_modules/@pl/pl-lib/assets/templates/searchable-dropdown/combobox-dropdown.component.html',
  styleUrls: [
    '../../../../plLibSetup/components/searchable-dropdown/combobox-dropdown.component.scss'
  ]
})
export class CoachSearchableDropdownComponent extends BaseComboboxDropdownComponent<Coach> {
  constructor(ngControl: NgControl, injector: Injector) {
    super(ngControl, injector);
    this.configurator = new CoachDropdownComponentConfigurator();
  }
}
