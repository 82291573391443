import { Component, OnDestroy, OnInit } from '@angular/core';
import { map, Observable, Subscription } from 'rxjs';
import { ListItemModel } from '@progress/kendo-angular-buttons';
import { LinkDescriptor } from 'src/app/core/models/link-descriptor';
import { AppSettingService } from 'src/app/core/services/appsetting';

interface QuickLinksItem extends ListItemModel {
  data: LinkDescriptor;
}

@Component({
  selector: 'app-quick-links',
  templateUrl: './quick-links.component.html',
  styleUrls: ['./quick-links.component.scss']
})
export class QuickLinksComponent implements OnInit, OnDestroy {
  data$: Observable<QuickLinksItem[]>;
  data: QuickLinksItem[];
  private sub: Subscription;

  constructor(private appSettingService: AppSettingService) {}

  ngOnInit(): void {
    this.data$ = this.appSettingService.appSetting$.pipe(
      map((s) => {
        if (s.quickLinks) {
          return s.quickLinks.map(
            (l: LinkDescriptor) =>
              ({
                text: l.label,
                icon: l.icon,
                data: l,
                click: (i: QuickLinksItem) => window.open(i.data.link)
              } as QuickLinksItem)
          );
        }
        return [];
      })
    );

    this.sub = this.data$.subscribe((e) => (this.data = e));
  }

  ngOnDestroy(): void {
    this.sub?.unsubscribe();
  }
}
