<app-pl-input-wrapper
[id]="id"
[label]="label"
inputType="custom"
[formControlName]="ngControl!.name"
[fieldConfig]="fieldConfig"
>
  <kendo-dropdownlist
    [data]="data"
    [readonly]="readonly"
    [textField]="textField"
    [valueField]="valueField"
    [formControlName]="ngControl!.name"
  >
  </kendo-dropdownlist>
</app-pl-input-wrapper>
