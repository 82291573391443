import { OrganisationService } from 'src/app/core/services/organisation';
import { OrganisationDisplayDataResolver } from '../../../../core/resolvers/displayData/organisation';
import { BaseDropdownComponentConfigurator } from '@pl/pl-lib/forms';

export class OrganisationDropdownComponentConfigurator extends BaseDropdownComponentConfigurator {
  constructor() {
    super();
    this.dataServiceType = OrganisationService;
    this.displayDataResolverType = OrganisationDisplayDataResolver;
  }
}
