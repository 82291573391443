import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  nav: HTMLDivElement;

  constructor() { }

  public ngOnInit(): void {
    this.nav = <HTMLDivElement>document.querySelector('.page__nav');
    this.setNavClass();
    this.onPageScroll();
  }

  private onPageScroll(): void {
    window.onscroll = () => {
      if(this.nav) {
        this.setNavClass();
      }

    }
  }

  private setNavClass(): void {
    if(window.scrollY >= 307) {
      this.nav.classList.add('sticky');
    } else {
      this.nav.classList.remove('sticky');
    }
  }
}
