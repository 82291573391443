import { BaseDropdownComponentConfigurator } from '@pl/pl-lib/forms';
import { CoachDisplayDataResolver } from 'src/app/core/resolvers/displayData/coach';
import { CoachService } from 'src/app/core/services/coach';

export class CoachDropdownComponentConfigurator extends BaseDropdownComponentConfigurator {
  constructor() {
    super();
    this.dataServiceType = CoachService
    this.displayDataResolverType = CoachDisplayDataResolver
  }
}
