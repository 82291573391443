<div class="profile-button-wrapper" *ngIf="user" (mouseenter)="show=true" (mouseleave)="show=false" #anchor>
  <div class="name" >
    <kendo-avatar
      [initials]="user.name.toUpperCase()"
      shape="circle"
      width="35px"
      height="35px"
    ></kendo-avatar>
  </div>
  <kendo-popup
    #popup
    [anchor]="anchor"
    [popupAlign]="popupAlign"
    [anchorAlign]="anchorAlign"
    *ngIf="show">
    <kendo-card>
      <kendo-card-header class="k-hstack">
        <kendo-avatar
          width="40px"
          height="40px"
          [initials]="user.name.toUpperCase()"
          shape="circle"
        ></kendo-avatar>
        <div>
          <h1 kendoCardTitle>{{user.name}}</h1>
          <p kendoCardSubtitle>{{user.email}}</p>
        </div>
      </kendo-card-header>

      <hr
        kendoCardSeparator
      />
      <kendo-card-actions
        orientation="vertical"
        layout="center"
      >
        <button
          kendoButton
          icon="user"
          fillMode="flat"
          [routerLink]="'/user/'+user.id">
          Profile
        </button>
        <button
          kendoButton
          icon="logout"
          fillMode="flat"
          (click)="onLogout()">
          Sign Out
        </button>
      </kendo-card-actions>
    </kendo-card>

  </kendo-popup>
</div>
