<pl-data-grid
  #baseDataGrid
  [data]="data"
  [defaultToolbar]="defaultToolbar"
  [showActions]="showActions"
  [tableId]="tableId"
  [isLoading]="isLoading"
  [state]="state"
  [groupable]="groupable"
  [paginated]="paginated"
  [pageSizes]="pageSizes"
  [resizable]="resizable"
  [filterable]="filterable"
  [defaultFilterableValue]="defaultFilterableValue"
  [sortable]="sortable"
  [editFormGetter]="editFormGetter"
  [customTemplateRef]="customTemplateRef"
  [showMenuColumn]="showMenuColumn"
  [menuItems]="menuItems"
  [actions]="actions"
  [selectable]="selectable"
  [selectionKeyGenerator]="selectionKeyGenerator"
  (cellClick)="cellClick.emit($event)"
  (menuItemClick)="menuItemClick.emit($event)"
  (dataStateChanged)="dataStateChanged.emit($event)"
  (customActionClicked)="customActionClicked.emit($event)"
  (addItemRequested)="addItemRequested.emit($event)"
  (deleteItemRequested)="deleteItemRequested.emit($event)"
  (editCanceled)="editCanceled.emit($event)"
  (editFinished)="editFinished.emit($event)"
  (editItemRequested)="editItemRequested.emit($event)"
  [showDetails]="showDetails"
  [rowClass]="rowClass || defaultRowClass"
  [isDetailExpanded]="$any(isDetailExpanded)"
  [customRowDetailsTemplate]="customRowDetailsTemplate"
  [getCustomFilterTemplate]="
    getCustomFilterTemplate || getCustomFilterTemplateMethod
  "
  [getCustomColumnTemplate]="
    getCustomColumnTemplate || getCustomColumnTemplateMethod
  "
  [getCustomEditorTemplate]="
    getCustomEditorTemplate || getCustomEditorTemplateMethod
  "
  [secureGridHandler]="secureGridHandler"
  [export]="export"
  [exportFileName]="exportFileName"
  [exportData]="$any(exportData)"
  [refresh]="false"
>
</pl-data-grid>

<ng-template
  #usersFilter
  let-plColumn="plColumn"
  let-kendoColumn="kendoColumn"
  let-filterService="filterService"
  let-currentItem
>
  <app-user-dropdown-filter
    [value]="
      baseDataGrid.getDropdownFilterValue(plColumn, filterService, currentItem)
    "
    (valueChanged)="
      baseDataGrid.onEnumDropdownValueChanged(
        plColumn,
        $event,
        filterService,
        currentItem
      )
    "
  >
  </app-user-dropdown-filter>
</ng-template>

<ng-template #fileSize let-plColumn="plColumn" let-dataItem="dataItem">
  {{ dataItem[plColumn.field] | formatFileSize }}
</ng-template>
