import { UserService } from 'src/app/core/services/user';
import { UserDisplayDataResolver } from '../../../../core/resolvers/displayData/user';
import { BaseDropdownComponentConfigurator } from '@pl/pl-lib/forms';

export class UserDropdownComponentConfigurator extends BaseDropdownComponentConfigurator {
  constructor() {
    super();
    this.dataServiceType = UserService;
    this.displayDataResolverType = UserDisplayDataResolver;
  }
}
