import { Injectable } from "@angular/core"
import { DisplayDataResolver } from "@pl/pl-lib/common"
import { isEmpty } from "lodash"
import { Coach } from "../../models/coach"

@Injectable({
  providedIn: "root",
})
export class CoachDisplayDataResolver
  implements DisplayDataResolver<Coach>
{
  typeDisplayName = "Coach"

  constructor() {}

  getIcon(entity: Coach): string | undefined {
    return "k-i-inherited"
  }

  getImage(entity: Coach): string | undefined {
    return undefined
  }

  getSubTitle(entity: Coach): string | undefined {
    return undefined
  }

  getTitle(entity: Coach): string {
    return `${entity.first_name} ${entity.last_name}`;
  }

  getShortTitle(entity: Coach): string {
    return entity.id
  }

  getItemHref(entityOrID: Coach | string): string | undefined {
    if (isEmpty(entityOrID)) {
      return "/app/coach"
    }
    if (typeof entityOrID === "string") return "/app/coach/" + entityOrID
    return "/app/coach/" + entityOrID.id
  }
}
