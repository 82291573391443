import {
  Component,
  EventEmitter,
  Input,
  Output,
  TemplateRef,
  ViewChild
} from '@angular/core';
import {
  DataGridColumn,
  FiltersSettings,
  ISecurityConfig,
  MenuItemClickEvent,
} from '@pl/pl-lib/data-grids';
import { DataResult, GroupDescriptor, State } from '@progress/kendo-data-query';
import {
  GridDataResult,
  RowArgs,
  RowClassFn,
  SelectableSettings,
  SortSettings
} from '@progress/kendo-angular-grid';
import { MenuItem } from '@progress/kendo-angular-menu';
import {ActionButton, appInjector, TemplateLibraryService} from '@pl/pl-lib/common';
import { DataGridComponent as PLDataGridComponent } from '@pl/pl-lib/data-grids';
import { FormGroup } from '@angular/forms';
import {
  EditEventArgs,
  FinishedEditEventArgs,
  ItemEditEventArgs
} from '@pl/pl-lib/data-grids/src/components/data-grid/data-grid.component';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-data-grid',
  templateUrl: './data-grid.component.html',
  styleUrls: ['./data-grid.component.scss']
})
export class DataGridComponent {
  @ViewChild('usersFilter')
  private usersFilter: TemplateRef<any>;
  @ViewChild('organisationFilter')
  private organisationFilter: TemplateRef<any>;
  @ViewChild('fileSize')
  private fileSize: TemplateRef<any>;

  @ViewChild('baseDataGrid')
  public baseGrid: PLDataGridComponent;

  getCustomFilterTemplateMethod: (
    column: DataGridColumn
  ) => null | TemplateRef<any>;
  getCustomColumnTemplateMethod: (
    column: DataGridColumn
  ) => TemplateRef<any> | null;
  getCustomEditorTemplateMethod: (
    column: DataGridColumn
  ) => TemplateRef<any> | null;

  @Input()
  data: DataResult | any[] | null;

  /**
   * Shows actions for which property is true.
   * Works only if actions property is not set (default value)
   */
  @Input() showActions: {
    refresh?: boolean;
    add?: boolean;
    delete?: boolean;
    toggleFilters?: boolean;
  } = {
    refresh: true,
    toggleFilters: true
  };

  @Input() rowClass?: RowClassFn;
  @Input() selectable: SelectableSettings | boolean = false;
  @Input() selectionKeyGenerator: (rowArgs: RowArgs) => string;
  @Input() editFormGetter: (
    dataItem: any,
    kendoSender: any,
    triggerDetails: any
  ) => FormGroup;
  @Input() defaultToolbar: boolean = true;
  @Input() tableId: string;
  @Input() isLoading: boolean;
  @Input() state: State;
  @Input() filterable: FiltersSettings;
  @Input() defaultFilterableValue: FiltersSettings;
  @Input() groupable?: boolean;
  @Input() group?: GroupDescriptor[];
  @Input() paginated: boolean = true;
  @Input() pageSizes: number[] = [5, 10, 20];
  @Input() resizable: boolean = true;
  @Input()
  secureGridHandler?: (grid: PLDataGridComponent) => Promise<ISecurityConfig>;

  @Input() sortable: SortSettings = {
    mode: 'single',
    initialDirection: 'asc',
    allowUnsort: true
  };
  @Input() customTemplateRef: TemplateRef<any>;
  @Input() showMenuColumn: boolean = false;
  @Input() menuItems: MenuItem[];

  @Input()
  public actions: { [index: string]: ActionButton };

  @Input()
  getCustomColumnTemplate: (column: DataGridColumn) => TemplateRef<any> | null;
  @Input()
  getCustomFilterTemplate: (column: DataGridColumn) => TemplateRef<any> | null;
  @Input()
  getCustomEditorTemplate: (column: DataGridColumn) => TemplateRef<any> | null;
  @Input() showDetails: boolean;
  @Input() isDetailExpanded?: (rowArgs: RowArgs) => boolean;
  @Input()
  customRowDetailsTemplate: TemplateRef<any> | null;
  @Output() cellClick: EventEmitter<any> = new EventEmitter();
  @Output() menuItemClick: EventEmitter<MenuItemClickEvent> =
    new EventEmitter();
  @Output() dataStateChanged: EventEmitter<State> = new EventEmitter();
  @Output() addItemRequested: EventEmitter<EditEventArgs> = new EventEmitter();
  @Output() editItemRequested: EventEmitter<ItemEditEventArgs> =
    new EventEmitter();
  @Output() editFinished: EventEmitter<FinishedEditEventArgs> =
    new EventEmitter();
  @Output() editCanceled: EventEmitter<FinishedEditEventArgs> =
    new EventEmitter();
  @Output() deleteItemRequested: EventEmitter<ItemEditEventArgs> =
    new EventEmitter();
  @Output() customActionClicked: EventEmitter<{
    action: ActionButton;
    dropdownItemClicked?: any;
  }> = new EventEmitter();

  @Input() export: boolean;
  @Input() exportData?: (
    grid: DataGridComponent
  ) => Observable<GridDataResult> | Promise<GridDataResult>;
  @Input() exportFileName: string = 'download';
  @Input() refresh: boolean;
  private templateLibraryService: TemplateLibraryService;

  public defaultRowClass(): string {
    return undefined as any;
  }

  constructor() {
    this.templateLibraryService = appInjector().get(TemplateLibraryService);
    this.getCustomColumnTemplateMethod = (column: DataGridColumn) => {
      if (!column.attributes) {
        return null;
      }

      switch (column.attributes['format']) {
        case 'fileSize':
          return this.fileSize;
        case 'kpi':
          return this.templateLibraryService.get('listCellTemplates', 'KpiCellTemplate') || null;

        default:
          return null;
      }
    };
    this.getCustomFilterTemplateMethod = (column: DataGridColumn) => {
      if (!column.filterable || !column.attributes) {
        return null;
      }

      switch (column.attributes['customFilter']) {
        case 'user':
          return this.usersFilter;
        case 'organisation':
          return this.organisationFilter;
        default:
          return null;
      }
    };
    this.getCustomEditorTemplateMethod = (column: DataGridColumn) => {
      if (!column.editable || !column.attributes) {
        return null;
      }
      return null;
    };
  }
}
