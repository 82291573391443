import { Component, EventEmitter, Input, Output } from '@angular/core';
import {
  SchedulerEvent,
  EventClickEvent,
} from '@progress/kendo-angular-scheduler';
@Component({
  selector: 'app-calendar',
  templateUrl: './calendar.component.html',
  styleUrls: ['./calendar.component.scss']
})
export class CalendarComponent {
  public selectedDate: Date = new Date();
  @Input() events: SchedulerEvent[];
  @Output() onEventClick = new EventEmitter<EventClickEvent>();
}
