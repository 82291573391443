import { Component } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { DialogRef } from '@progress/kendo-angular-dialog';
import { Observable } from 'rxjs';
import { DropdownItem } from '@pl/pl-lib/forms';
import { ActionButton, BaseEntity } from '@pl/pl-lib/common';

@Component({
  selector: 'app-existing-entity',
  templateUrl: './existing-entity.component.html',
  styleUrls: ['./existing-entity.component.scss']
})
export class ExistingEntityComponent<T extends BaseEntity> {
  public title: string;
  public data$: Observable<DropdownItem[]>;
  public form: FormGroup = new FormGroup({
    control: new FormControl(undefined, Validators.required)
  });
  public actions: ActionButton[] = [
    {
      text: 'Save',
      themeColor: 'primary',
      icon: 'save',
      onClick: () => this.save()
    } as ActionButton
  ];
  public fetchedData: T[];

  constructor(protected dialogRef: DialogRef) {}

  public save(): void {
    if (this.form.valid) {
      this.dialogRef.close({
        saved: true,
        entity: this.fetchedData.find(
          (item) =>
            item.id === (this.form.get('control')?.value as DropdownItem).value
        )
      });
    } else {
      this.form.get('control')?.markAsTouched();
    }
  }
}
