<div class="page">
    <div class="page__body">
        <div class="page__body-section">
            <h1>Welcome</h1>
            <p>Football is a diverse sport which brings together communities and cultures from all backgrounds. This diversity has made the game stronger on the pitch and it is vital we ensure this is reflected across all areas of the game.</p>
            <p>The Premier League, The FA, the EFL, the Professional Footballers Association Charity and the LMA are committed to working in partnership with clubs and all football partners to increase the representation of Coaches who are men from black, Asian and mixed heritage backgrounds and women all backgrounds at Academy and Senior levels of the professional game, as well as helping to create more inclusive and diverse environments within the coaching workforce that better represents the diversity of football and society.</p>
        </div>

        <div class="page__body-section">
            <h1>Purpose</h1>
            <p>The Coach Index will provide all professional teams in the men’s and women’s game respectively Clubs with a directory of coaches from currently under-represented groups who possess a minimum of a UEFA C Licence / FA Level 2 qualification in coaching football that is up to date and active. Enabling coaches from these populations, to have greater visibility in their profession, better opportunities to enhance their careers and continue to develop and learn their craft.</p>
            <p>The Coach Index may be accessed by clubs commencing a formal recruitment process. Clubs may filter individuals into a shortlist, in order to signpost job opportunities to selected coaches. It is therefore a tool for clubs to learn more about the existing talent pool of coaches from currently underrepresented groups, their qualifications and work experience to ensure that there is a fully inclusive approach towards the recruitment process.</p>
            <p>With this targeted approach we hope to galvanise currently underrepresented communities into pursuing coaching careers in the knowledge that clubs and the professional game partners alike are showing a commitment to improving the diversity of the coaching workforce in support of the Premier League’s No Room For Racism Action Plan and the FA Leadership Diversity Code</p>
        </div>


        <div class="page__body-section">
            <h1>Principles</h1>
            <p>The Coach Index has been developed to help to address a specific and long-standing issue within English professional football and its creation brings about an opportunity to affect change in an area of the game where more progress is required.</p>

<p>With this in mind, the Coach Index is to be treated and used in a spirit that understands the current landscape of the lack of diversity in coaching whilst still adhering to the following principles:</p>

<p>It should be used to compliment existing practices during a formal recruitment process, not as a replacement or another form of network-based recruitment</p>

<p>
    <ul>
        <li>    Opportunities provided by way of the ‘Coach Index’ should always be provided on merit first
        </li>
        <li>It is not an exhaustive list of individuals and just one of the ways that we can help clubs to have more inclusive recruitment processes
        </li>
        <li>The highlighting of individuals from currently under-represented groups in this way is by no means any endorsement of character, skill or expertise
        </li>
    </ul>
        </div>
    </div>
</div>
