import { Component, Injector } from '@angular/core';
import { NgControl } from '@angular/forms';
import { CountryDropdownComponentConfigurator } from './country-dropdown-component.configurator';
import { BaseComboboxDropdownComponent } from '@pl/pl-lib/forms';
import { Country } from 'src/app/core/models/country';

@Component({
  selector: 'app-country-searchable-dropdown',
  templateUrl:
    '../../../../../../node_modules/@pl/pl-lib/assets/templates/searchable-dropdown/combobox-dropdown.component.html',
  styleUrls: [
    '../../../../plLibSetup/components/searchable-dropdown/combobox-dropdown.component.scss'
  ]
})
export class CountrySearchableDropdownComponent extends BaseComboboxDropdownComponent<Country> {
  constructor(ngControl: NgControl, injector: Injector) {
    super(ngControl, injector);
    this.configurator = new CountryDropdownComponentConfigurator();
  }
}
