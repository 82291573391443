import { Component, Injector } from '@angular/core';
import { NgControl } from '@angular/forms';
import { DepartmentDropdownComponentConfigurator } from './department-dropdown-component.configurator';
import { BaseComboboxDropdownComponent } from '@pl/pl-lib/forms';
import { Department } from 'src/app/core/models/department';

@Component({
  selector: 'app-department-searchable-dropdown',
  templateUrl:
    '../../../../../../node_modules/@pl/pl-lib/assets/templates/searchable-dropdown/combobox-dropdown.component.html',
  styleUrls: [
    '../../../../plLibSetup/components/searchable-dropdown/combobox-dropdown.component.scss'
  ]
})
export class DepartmentSearchableDropdownComponent extends BaseComboboxDropdownComponent<Department> {
  constructor(ngControl: NgControl, injector: Injector) {
    super(ngControl, injector);
    this.configurator = new DepartmentDropdownComponentConfigurator();
  }
}
