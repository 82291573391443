import { BaseDropdownComponentConfigurator } from '@pl/pl-lib/forms';
import { RegionDisplayDataResolver } from 'src/app/core/resolvers/displayData/region';
import { RegionService } from 'src/app/core/services/region';

export class RegionDropdownComponentConfigurator extends BaseDropdownComponentConfigurator {
  constructor() {
    super();
    this.dataServiceType = RegionService
    this.displayDataResolverType = RegionDisplayDataResolver
  }
}
