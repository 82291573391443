import {
  ActivatedRouteSnapshot,
  BaseRouteReuseStrategy
} from '@angular/router';
export class RouteReuseService extends BaseRouteReuseStrategy {
  override shouldReuseRoute(
    future: ActivatedRouteSnapshot,
    current: ActivatedRouteSnapshot
  ): boolean {
    let ignoreReuse = false;
    if (future.routeConfig) {
      if (future.routeConfig.data) {
        ignoreReuse = future.routeConfig.data['ignoreReuse'];
      }
    }

    return ignoreReuse;
  }
}
