import { Injectable } from "@angular/core"
import { DisplayDataResolver } from "@pl/pl-lib/common"
import { isEmpty } from "lodash"
import { Event } from "../../models/event"

@Injectable({
  providedIn: "root",
})
export class EventDisplayDataResolver implements DisplayDataResolver<Event> {
  typeDisplayName = "Event"

  constructor() {}

  getIcon(entity: Event): string | undefined {
    return "k-i-inherited"
  }

  getImage(entity: Event): string | undefined {
    return undefined
  }

  getSubTitle(entity: Event): string | undefined {
    return undefined
  }

  getTitle(entity: Event): string {
    return entity.name
  }

  getShortTitle(entity: Event): string {
    return entity.id
  }

  getItemHref(entityOrID: Event | string): string | undefined {
    if (isEmpty(entityOrID)) {
      return "/app/event"
    }
    if (typeof entityOrID === "string") return "/app/event/" + entityOrID
    return "/app/event/" + entityOrID.id
  }
}
