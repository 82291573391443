<kendo-formfield class="input" [ngClass]="{'input--checkbox': formItem.type === 'checkbox'}">
    <kendo-label class="input__label" for="first_name" *ngIf="formItem.showLabel && formItem.type !== 'checkbox'">
        <span *ngIf="formItem.required" class="input__label-required">*</span>
        {{formItem.placeholder}} <span *ngIf="formItem.required">(required)</span>
    </kendo-label>
    <div class="input__field">
        <div *ngIf="formItem.type === 'checkbox'">
            <input
                kendoCheckBox
                type="checkbox"
                [formControl]="formControl"
            />
            <kendo-label
              class="k-checkbox-label"
              [for]="formControl"
              [text]="formItem.placeholder"
            ></kendo-label>    
        </div>

        <kendo-textbox
            *ngIf="formItem.type === 'text' || formItem.type === 'email'"
            class="input__field-textbox"
            [id]="formItem.name"
            [email]="formItem.type === 'email'"
            [placeholder]="formItem.placeholder"
            [formControl]="formControl">
        </kendo-textbox>
        <kendo-datepicker
            class="input__field-textbox"
            *ngIf="formItem.type === 'date'"
            placeholder="Choose a date ..."
            [formControl]="formControl"
            [id]="formItem.name"
        ></kendo-datepicker>
        <kendo-dropdownlist
            *ngIf="formItem.type === 'dropdown'"
            [formControl]="formControl"
            [id]="formItem.name"
            [data]="formItem.dropdownData"
            textField="name"
            valueField="id"
            placeholder="Choose a date ..."
            class="input__field-textbox"
            [valuePrimitive]="true"
            >
        </kendo-dropdownlist>
        <button
        *ngIf="!hideButton && formItem.type !== 'checkbox'"
        [disabled]="formItem.isHidden ? true: false"
        class="input__field-button"
        kendoButton
        themeColor="base"
        size="large"
        (click)="toggle()">
            <span *ngIf="formItem.isHidden && !formItem.toggled"><kendo-icon name="lock" size="large"></kendo-icon> Always Hidden</span>
            <span *ngIf="formItem.toggled && !formItem.isHidden"><kendo-icon name="lock" size="large"></kendo-icon> Hidden from clubs</span>
            <span *ngIf="!formItem.toggled && !formItem.isHidden"><kendo-icon name="unlock" size="large"></kendo-icon> Visible to clubs</span>
        </button>
    </div>
    <p
        *ngIf="formItem.description"
        class="input__description"
        [innerHTML]="formItem.description">
    </p>
    <p
        *ngIf="formItem.footNote"
        class="input__footNote">
        {{formItem.footNote}}
    </p>
</kendo-formfield>