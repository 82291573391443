import { Injectable } from "@angular/core"
import { DisplayDataResolver } from "@pl/pl-lib/common"
import { isEmpty } from "lodash"
import { CoachQualification } from "../../models/coachqualification"

@Injectable({
  providedIn: "root",
})
export class CoachQualificationDisplayDataResolver
  implements DisplayDataResolver<CoachQualification>
{
  typeDisplayName = "CoachQualification"

  constructor() {}

  getIcon(entity: CoachQualification): string | undefined {
    return "k-i-inherited"
  }

  getImage(entity: CoachQualification): string | undefined {
    return undefined
  }

  getSubTitle(entity: CoachQualification): string | undefined {
    return undefined
  }

  getTitle(entity: CoachQualification): string {
    return entity.id
  }

  getShortTitle(entity: CoachQualification): string {
    return entity.id
  }

  getItemHref(entityOrID: CoachQualification | string): string | undefined {
    if (isEmpty(entityOrID)) {
      return "/app/coachQualification"
    }
    if (typeof entityOrID === "string") return "/app/coachQualification/" + entityOrID
    return "/app/coachQualification/" + entityOrID.id
  }
}
