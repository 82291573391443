import { Component, Input, OnInit, Optional, TemplateRef, ViewChild, ViewContainerRef } from '@angular/core';
import { process, State } from '@progress/kendo-data-query';
import { GridDataResult, RowArgs, SelectableSettings } from '@progress/kendo-angular-grid';
import { ActivatedRoute, Router } from '@angular/router';
import {
  ActionButton,
  BaseApiService,
  BaseEntity, DisplayDataResolver,
  FeedbackMessage, PaginatedResult
} from '@pl/pl-lib/common';
import { appInjector } from "@pl/pl-lib/common";
import { PlEntityListConfigurator } from "./pl-entity-list.configurator";
import { map, Observable, shareReplay } from "rxjs";
import { DataGridComponent } from "../data-grid/data-grid.component";
import {
  EditEventArgs, FinishedEditEventArgs, ItemEditEventArgs, DataGridComponent as PLDataGridComponent,
  FiltersSettings
} from "@pl/pl-lib/data-grids";

@Component({
  selector: 'pl-entity-list',
  templateUrl: './pl-entity-list.component.html',
  styleUrls: ['./pl-entity-list.component.scss']
})
export class PlEntityListComponent<T extends BaseEntity> implements OnInit {
  @ViewChild('grid')
  public grid: DataGridComponent;
  @ViewChild("appendTo", { read: ViewContainerRef, static: false })
  public appendTo: ViewContainerRef;

  private _configurator: PlEntityListConfigurator;

  @Input()
  public actions: { [index: string]: ActionButton };

  @Input() filtersSettings: FiltersSettings;

  @Input() selectable: SelectableSettings | boolean = false;
  public gridData: GridDataResult;
  public isLoading: boolean = true;
  public state: State = {
    skip: 0,
    take: 10
  };
  private obs$: Observable<PaginatedResult<any>> | undefined;
  messages: FeedbackMessage[];
  private fetchedData: PaginatedResult<any>;


  private countObs$: Observable<number> | undefined;
  private countResult: number;

  get configurator(): PlEntityListConfigurator {
    return this._configurator;
  }

  @Input()
  set configurator(value: PlEntityListConfigurator) {
    this._configurator = value;
    this.fillInputsFromConfigurator()
  }

  @Input()
  public service: BaseApiService<T, any, any>;
  @Input()
  public displayDataResolver: DisplayDataResolver<T>;

  @Input()
  public tableId: string;

  @Input()
  public title: string;

  @Input()
  public loadOnInit: boolean = true;

  @Input()
  public paginated: boolean = true;
  @Input()
  public customToolbarTemplate: TemplateRef<any>;
  @Input()
  public customRowDetailsTemplate: TemplateRef<any>;
  @Input()
  export: boolean;
  @Input() 
  refresh: boolean;

  constructor(
    protected router: Router,
    @Optional()
    service: BaseApiService<T, any, any>,
    protected route: ActivatedRoute
  ) {
    this.service = service;
  }

  async ngOnInit() {
    if (this.configurator) {
      this.configurator.grid = this;
    }
    if (this.configurator && !this.configurator.loadOnInit || !this.loadOnInit) {
      await this.showMessages();
      return;
    }
    this.fetch();
  }

  public async showMessages(messages?: any[]): Promise<void> {
    if (this.configurator.getMessages) {
      this.messages = (await this.configurator.getMessages() || []).concat(messages || []);
    } else {
      this.messages = messages || [];
    }
  }

  public cellClickHandler(entity: T): void {
    if (this.configurator.selectable) {
      return;
    }
    if (this.configurator.cellClickHandler) {
      return this.configurator.cellClickHandler(entity, this.router, this.route);
    }

    const url = this.displayDataResolver.getItemHref(entity);
    if (url) {
      this.router.navigateByUrl(url);
    }
  }

  public dataStateChangeHandler(state: State): void {
    this.state = state;
    if (this.configurator.clientFilterable) {
      if (this.fetchedData.data.length !== this.fetchedData.count) {
        throw new Error('ClientFilterable option should only be used if all the grid data is loaded (no pagination)');
      }
      this.gridData = process(this.fetchedData.data, state);
    } else {
      this.fetch();
    }
  }

  public count(): Observable<number> {
    const { count } = this.getItemsGetterObservable();
    this.countObs$ = count.pipe(shareReplay({ bufferSize: 1, refCount: true }));

    this.countObs$.subscribe({
      next: async (result) => {
        this.countResult = result;
        this.countObs$ = undefined;
      }, error: async e => {
        await this.showMessages([{ message: e?.message || e, status: "error" }]);
      }
    });
    return this.countObs$;
  }

  public fetch(): Observable<PaginatedResult<any>> {
    if (this.isLoading && this.obs$) {
      return this.obs$;
    }
    this.isLoading = true;

    const { data } = this.getItemsGetterObservable();
    this.obs$ = data.pipe(shareReplay({ bufferSize: 1, refCount: true }));

    this.obs$.subscribe({
      next: async (result) => {
        if (result.count === 1 && this.configurator.redirectIfSingle) {
          const url = this.displayDataResolver.getItemHref(result.data[0].id);
          if (url) {
            this.router.navigateByUrl(url);
            this.isLoading = false;
            this.obs$ = undefined;
            return;
          }
        }
        await this.showMessages();
        this.fetchedData = result;

        this.gridData = {
          data: process(result.data, {
            filter: this.state.filter,
            group: this.state.group,
            sort: this.state.sort
          }).data,
          total: result.count
        };

        this.isLoading = false;
        this.obs$ = undefined;
      }, error: async e => {
        this.isLoading = false;
        await this.showMessages([{ message: e?.message || e, status: "error" }]);
      }
    });
    return this.obs$;
  }

  public selectionKeyGenerator(context: RowArgs) {
    if (this.configurator.selectionKeyGenerator) {
      return this.configurator.selectionKeyGenerator(context);
    }
    return context.dataItem.id;
  }

  public editFormGetter(dataItem: any, kendoSender: any, triggerDetails: any) {
    if (this.configurator.editFormGetter) {
      return this.configurator.editFormGetter(dataItem, kendoSender, triggerDetails);
    }
    throw new Error('Edit action requested and configurator.editFormGetter property not set. Please provide EditFormGetter');
  }

  public isDetailExpanded(rowArgs: RowArgs) {
    if (this.configurator.isDetailExpanded) {
      return this.configurator.isDetailExpanded(rowArgs);
    }
    return undefined as any as boolean;
  }

  public handleAddItemRequest($event: EditEventArgs) {
    if (this.configurator.handleAddItemRequest) {
      return this.configurator.handleAddItemRequest($event);
    }
    throw new Error('Add Item handle requested but not provided in configurator.');
  }

  public async secureGridHandler(grid: PLDataGridComponent) {
    if (this.configurator.secureGridHandler) {
      return await this.configurator.secureGridHandler(grid);
    }
    return {};
  }

  public handleDeleteItemRequest($event: ItemEditEventArgs) {
    if (this.configurator.handleDeleteItemRequest) {
      return this.configurator.handleDeleteItemRequest($event);
    }
    throw new Error('Delete Item handle requested but not provided in configurator.');
  }

  public handleEditItemRequest($event: ItemEditEventArgs) {
    if (this.configurator.handleEditItemRequest) {
      return this.configurator.handleEditItemRequest($event);
    }
    throw new Error('Delete Item handle requested but not provided in configurator.');
  }

  public handleEditFinished($event: FinishedEditEventArgs) {
    if (this.configurator.handleEditFinished) {
      return this.configurator.handleEditFinished($event);
    }
    throw new Error('Delete Item handle requested but not provided in configurator.');
  }

  public handleEditCanceled($event: FinishedEditEventArgs) {
    if (this.configurator.handleEditCanceled) {
      return this.configurator.handleEditCanceled($event);
    }
    throw new Error('Delete Item handle requested but not provided in configurator.');
  }


  customActionClicked($event: { action: ActionButton, dropdownItemClicked?: any }) {
    if (this.configurator.customActionClicked) {
      return this.configurator.customActionClicked($event);
    }
    throw new Error('Custom Action Clicked handle requested but not provided in configurator.');
  }

  private getItemsGetterObservable(): { data: Observable<PaginatedResult<any>>, count: Observable<number> } {
    if (this.service && this._configurator) {
      throw new Error('If configurator is provided the inputs should not be set.');
    }
    if (this.service) {
      return {
        data: this.service.getAllPaginated(this.state),
        count: this.service.count(this.state)
      }
    }
    if (this._configurator) {
      if (this._configurator.dataLoaderType === 'baseApiService') {
        if (!this._configurator.dataLoaderService && !this._configurator.dataLoaderServiceType) {
          throw new Error('Configurator with dataLoaderType===\'baseApiService\' has to have dataLoaderService or dataLoaderServiceType property provided.')
        }
        return {
          data: (this._configurator.dataLoaderService || appInjector().get(this._configurator.dataLoaderServiceType!)).getAllPaginated(this.state),
          count: (this._configurator.dataLoaderService || appInjector().get(this._configurator.dataLoaderServiceType!)).count(this.state)
        }
      }
      if (this._configurator.dataLoaderType === 'custom') {
        if (!this._configurator.dataLoaderFunction) {
          throw new Error('Configurator with dataLoaderType===\'custom\' has to have dataLoaderFunction provided.')
        }
        const sharedDataLoader = this._configurator.dataLoaderFunction(this.state).pipe(
          shareReplay({
            bufferSize: 1,
            refCount: true
          })
        );
        return {
          data: sharedDataLoader,
          count: this._configurator.countLoaderFunction ? this._configurator.countLoaderFunction(this.state) : sharedDataLoader.pipe(map(res => res.count))
        };
      }
    }

    throw new Error('Not supported grid configuration.');
  }

  private fillInputsFromConfigurator() {
    this.tableId = this.configurator.tableId;
    this.displayDataResolver = this.configurator.displayDataResolver;
    this.loadOnInit = this.configurator.loadOnInit;
    this.paginated = this.configurator.paginated;
    this.state = this.configurator.initialState || this.state;
    this.selectable = this.configurator.selectable || false;
    this.export = this.configurator.export || false;
    this.refresh = !this.configurator.refresh ? this.configurator.refresh : true;
    this.filtersSettings = this.configurator.filterable !== undefined ? this.configurator.filterable : this.filtersSettings;
  }

}
