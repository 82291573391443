import {
  Component,
  Input,
  OnInit,
  ViewChild,
  ViewContainerRef
} from '@angular/core';
import { IEntityDetailsSection } from "../app-entity-detail/app-entity-detail.component";

@Component({
  selector: 'pl-entity-custom-component',
  templateUrl: './pl-entity-custom-component.component.html'
})
export class PlDynamicComponent implements OnInit {

  @Input() entity: any;
  @Input() sectionConfig: any;

  @ViewChild('viewContainer', {static: true, read:ViewContainerRef})
  viewContainer!: ViewContainerRef;

  ngOnInit() {
    this.viewContainer.clear();

    const s = this.sectionConfig;

    const componentRef = this.viewContainer.createComponent<IEntityDetailsSection<any>>(s.componentType);
    componentRef.instance.title = s.title;
    componentRef.instance.isExpanded = s.isExpanded;
    if(this.sectionConfig.initialize){
      this.sectionConfig.initialize(componentRef.instance, this.entity, s);
    }
  }
}
