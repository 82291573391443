import {
  Component,
  Inject,
  Input,
  OnInit,
  ViewChild,
  ViewContainerRef
} from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import {
  AppTitleService,
  BaseApiService, BaseDataService,
  BaseDetailComponent,
  BaseEntity,
  BaseNotificationService, DisplayDataResolver,
  PermissionsService,
  appInjector
} from '@pl/pl-lib/common';
import { TranslateService } from '@ngx-translate/core';
import { DialogService } from "@progress/kendo-angular-dialog";
import { PlFormConfig } from "@pl/pl-lib/forms";
import { Orientation } from "@progress/kendo-angular-layout";

export interface IEntityDetailsSection<T> {
  isExpanded?: boolean;
  title?: string;
  icon?: string;
}

interface BaseSectionConfig {
  type?: 'view' | 'custom' | 'flex';
  flex?: number;
}
export interface BaseFormSectionConfig extends BaseSectionConfig {
  isExpanded: boolean;
  title: string;
  icon?: string;
}
export interface LayoutSectionConfig extends BaseSectionConfig {
  type?: 'flex';
  direction?: Orientation;
  gap?: string;
  sections: FormSection[];
}
export interface ViewSectionConfig extends BaseFormSectionConfig {
  type?: 'view';
  viewName: string;
}
export interface CustomSectionConfig extends BaseFormSectionConfig {
  type?: 'custom';
  componentType: new () => IEntityDetailsSection<any>;
  initialize?: (c: IEntityDetailsSection<any>, d: BaseDetailComponent<any, any, any, any, any>, sectionConfig: CustomSectionConfig) => void;
}
type FormSection = CustomSectionConfig | ViewSectionConfig | LayoutSectionConfig;

@Component({
  selector: 'app-base-entity-detail',
  templateUrl: './app-entity-detail.component.html',
  styleUrls: ['./app-entity-detail.component.scss']
})
export class AppEntityDetailComponent<T extends BaseEntity,
  TCreate,
  TUpdate extends { id?: string | undefined; },
  TDataService extends BaseDataService<T, TCreate, TUpdate, BaseApiService<T, TCreate, TUpdate>>,
  TDisplayDataResolver extends DisplayDataResolver<T>>
  extends BaseDetailComponent<T,
  TCreate,
  TUpdate,
  TDataService,
  TDisplayDataResolver> implements OnInit {

  @ViewChild('sections', { static: true, read: ViewContainerRef })
  sections!: ViewContainerRef;

  @Input()
  public metadataFormConfig: PlFormConfig;
  @Input()
  public formConfig: PlFormConfig;
  @Input()
  public sectionsConfig: FormSection[];
  @Input()
  public showHistorySection: boolean = true;

  constructor(
    @Inject('EMPTY_RESOLVER')
    dataService: TDataService,
    @Inject('EMPTY_RESOLVER')
    displayDataResolver: TDisplayDataResolver,
    route: ActivatedRoute,
    router: Router,
    translateService: TranslateService,
    notificationService: BaseNotificationService,
    formBuilder: FormBuilder,
    permissionsService: PermissionsService,
    dialogService: DialogService,
    @Inject(false)
    allowDelete: boolean = false
  ) {
    super(
      dataService,
      route,
      router,
      translateService,
      displayDataResolver,
      notificationService,
      formBuilder,
      permissionsService,
      dialogService,
      appInjector().get(AppTitleService),
      allowDelete
    );
  }

  protected initForm(): FormGroup {
    throw new Error('Please override in derived component');
  }

  protected mapFormToCreateDto(): TCreate {
    throw new Error('Please override in derived component');
  }

  protected mapFormToUpdateDto(): TUpdate {
    throw new Error('Please override in derived component');
  }

  private initFormSectionConfig(sections: FormSection[]) {
    if (!sections) return;

    if (sections.length > 0) {
      sections?.forEach(s => {
        if ("viewName" in s && s.viewName) {
          s.type = 'view';
        } else if ("componentType" in s && s.componentType) {
          s.type = 'custom';
        } else {
          (s as LayoutSectionConfig).type = 'flex';
          (s as LayoutSectionConfig).flex = (s as LayoutSectionConfig).flex || 1;
          (s as LayoutSectionConfig).direction = (s as LayoutSectionConfig).direction || "vertical";
          (s as LayoutSectionConfig).gap = (s as LayoutSectionConfig).gap || "16px";
          if ((s as LayoutSectionConfig).sections) this.initFormSectionConfig((s as LayoutSectionConfig).sections);
        }
      });
    }
  }

  ngOnInit(): void {
    this.initFormSectionConfig(this.sectionsConfig);
  }
}
