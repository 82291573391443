import { Component, Input } from "@angular/core";

@Component({
  selector: "loading-component",
  styles: [
    `
    .custom-loading {
        width: 16rem;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: 14rem;
    }
    `
  ],
  template: `
    <div class="custom-loading">
        <kendo-loader type="pulsing" size="large"></kendo-loader>
        <p>{{text}}</p>
    </div>
  `,
})
export class CustomLoadingComponent {
    @Input() text = 'Loading...';
}