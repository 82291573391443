<ng-container contentTop>
  <div class="content-header-wrapper">
    <pl-lib-header-content-layout
      [title]="title"
      [actions]="actions"
      [hasBreadcrumb]="isEntityDetailsRouteHandler"
    >

    </pl-lib-header-content-layout>
  </div>
</ng-container>

<pl-lib-form-content-layout>
  <ng-container metadata *ngIf="!isNew">
    <app-pl-form [formGroup]="$any(form.get('metadata'))" [config]="metadataFormConfig"></app-pl-form>
  </ng-container>

  <ng-template #recursiveSectionsTemplate let-sections="sections">
    <ng-container *ngFor="let section of sections">
      <ng-container *ngIf="section.type === 'flex'">
        <kendo-stacklayout
          [style.flex]="section.flex"
          [style.overflow]="'hidden'"
          [orientation]="section.direction"
          [gap]="section.gap"
          [align]="{ vertical: 'top' }">
          <ng-container
            *ngTemplateOutlet="recursiveSectionsTemplate; context:{ sections: section['sections'] }"
          ></ng-container>
        </kendo-stacklayout>
      </ng-container>
      <ng-container *ngIf="section.type !== 'flex'">
        <kendo-stacklayout
          [style.flex]="section.flex"
          [style.overflow]="'hidden'"
          orientation="vertical"
          [align]="{ vertical: 'top' }">
          <ng-container *ngIf="section.type === 'view'">
            <pl-base-form-section [form]="form" [isExpanded]="section.isExpanded" [formConfig]="formConfig" [view]="section.viewName" [title]="section.title" [icon]="section.icon"></pl-base-form-section>
          </ng-container>
          <ng-container *ngIf="section.type === 'custom'">
            <pl-entity-custom-component [entity]="this" [sectionConfig]="section"></pl-entity-custom-component>
          </ng-container>
        </kendo-stacklayout>
      </ng-container>
    </ng-container>
  </ng-template>

  <ng-container formFields>
    <ng-container
      *ngTemplateOutlet="recursiveSectionsTemplate; context:{ sections: sectionsConfig }"
    ></ng-container>
  </ng-container>

  <ng-container formRelatedContracted *ngIf="!isNew">
    <div *ngIf="entity && showHistorySection" class="k-card sidebar-preview"><pl-icon icon="k-i-reset" type="kendo"></pl-icon></div>
  </ng-container>

  <ng-container formRelated *ngIf="!isNew">
    <app-base-history-section [service]="$any(service)"
                              *ngIf="entity && showHistorySection"
                              [id]="entity.id"
                              [refreshTrigger]="entityChanged$"
    ></app-base-history-section>
  </ng-container>
</pl-lib-form-content-layout>
