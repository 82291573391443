import { Injectable } from "@angular/core"
import { HttpClient } from "@angular/common/http"
import {
  BaseCacheableApiService,
  BaseEnvironmentService,
} from "@pl/pl-lib/common"

import { Job } from "../models/job"
import { CreateJob } from "../models/dtos/job/job.create-dto"
import { UpdateJob } from "../models/dtos/job/job.update-dto"

@Injectable({
  providedIn: "root",
})
export class JobService extends BaseCacheableApiService<
  Job,
  CreateJob,
  UpdateJob
> {
  constructor(http: HttpClient, environmentService: BaseEnvironmentService) {
    super("jobs", http, environmentService, ["providerId"])
  }
}
