<ng-container contentTop>
  <div class="content-header-wrapper">
    <pl-lib-header-content-layout
      [title]="title"
      [actions]="actions"
      [hasBreadcrumb]="false"
    ></pl-lib-header-content-layout>
  </div>
</ng-container>
<pl-lib-form-content-layout>
  <ng-container formFields>
    <form class="k-form" *ngIf="form" [formGroup]="form">
      <app-pl-input-wrapper
        id="control"
        [label]="title"
        inputType="custom"
        formControlName="control">
          <kendo-dropdownlist
            [data]="data$ | async"
            formControlName="control"
            textField="text"
            valueField="value">
          </kendo-dropdownlist>
      </app-pl-input-wrapper>
    </form>
  </ng-container>
</pl-lib-form-content-layout>
