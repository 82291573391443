import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BaseNotificationService, PermissionsService } from '@pl/pl-lib/common';
import { DialogService } from '@progress/kendo-angular-dialog';
import { firstValueFrom, lastValueFrom, map } from 'rxjs';
import { Coach } from 'src/app/core/models/coach';
import { CoachDesiredDepartment } from 'src/app/core/models/coachdesireddepartment';
import { CoachDesiredRegion } from 'src/app/core/models/coachdesiredregion';
import { CoachQualification } from 'src/app/core/models/coachqualification';
import { CoachGender } from 'src/app/core/models/enums/coach-gender.enum';
import { CoachIdentity } from 'src/app/core/models/enums/coach-identity.enum';
import { CoachService } from 'src/app/core/services/coach';
import { CustomLoadingComponent } from 'src/app/shared/components/loading/loading.component';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit {
  public id: string | null;
  public coach: Coach;
  public departments: CoachDesiredDepartment[];
  public regions: CoachDesiredRegion[] = [];
  public ethnic_identity: string;
  public canEdit = false;
  public canDelete = false;
  public canApprove = false;
  public isClub = false;
  public CoachGender = CoachGender;
  public highestCoreQualification?: CoachQualification;

  constructor(
    private readonly coachService: CoachService,
    private route: ActivatedRoute,
    private readonly permissionsService: PermissionsService,
    private dialogService: DialogService,
    private entityDialogService: DialogService,
    private notificationService: BaseNotificationService,
    private router: Router
  ) {}

  async ngOnInit(): Promise<void> {
    this.id = this.route.snapshot.paramMap.get('id');
    if (this.id)
      this.coach = await lastValueFrom(this.coachService.get(this.id));

    this.ethnic_identity = CoachIdentity[this.coach.ethnic_identity];

    this.highestCoreQualification = this.getHighestCoreQualification();
    

    this.departments = this.coach.coachDesiredDepartments;
    this.regions = this.coach.coachDesiredRegions;

    this.isClub = await this.permissionsService.hasPermission(
      '186CoachIndex.Views.Club',
      'Read'
    );

    this.canApprove = await this.permissionsService.hasPermission(
      '186CoachIndex.ciPendingApprovals',
      'Write'
    );
    this.canEdit = await this.permissionsService.hasPermission(
      '186CoachIndex.ciCoach',
      'Write'
    );
    this.canDelete = await this.permissionsService.hasPermission(
      '186CoachIndex.ciCoach',
      'Delete'
    );
  }

  private getHighestCoreQualification(): CoachQualification | undefined {
    const foundHighest = this.coach.coachQualifications.filter((qualification) => {
      return (qualification.course.core)
    }).sort((a, b) => a.course.order - b.course.order);

    if(foundHighest.length !== 0) return foundHighest[0];

    return undefined;
  }

  public async approveCoach() {
    const modal = this.entityDialogService.open({
      content: CustomLoadingComponent,
      cssClass: 'on-top-of-windows word-break'
    });

    const approvalLoading = modal.content.instance as CustomLoadingComponent;
    approvalLoading.text = 'Submitting approval of coach...';

    this.coachService.approveCoach(this.coach.id).subscribe({
      next: () => {
        this.notificationService.showSuccess(
          `${this.coach.first_name} ${this.coach.last_name} has been approved`
        );
        modal.close();
      },
      error: () => {
        this.notificationService.showError(
          `Unable to approve ${this.coach.first_name} ${this.coach.last_name}`
        );
        modal.close();
      }
    });
  }

  public deleteCoach = async (): Promise<void> => {
    if (
      !this.coach ||
      !(await this.permissionsService.hasPermission('186CoachIndex.ciCoach', 'Delete'))
    ) {
      return;
    }
    const modal = this.entityDialogService.open({
      title: 'Are you sure?',
      content: `This will delete your profile from Coach Index.`,
      actions: [
        { text: 'Cancel' },
        { text: 'Submit', id: 'submit', themeColor: 'primary' }
      ],
      cssClass: 'on-top-of-windows word-break'
    });
    const b = firstValueFrom(
      modal.result.pipe(
        map(async (r: any) => {
          if (r.id === 'submit' && this.coach) {
            this.notificationService.showSuccess(
              `${this.coach?.first_name} ${this.coach?.last_name} has been deleted`
            );
            this.router.navigateByUrl('/');

            await this.coachService.delete(this.coach.id).subscribe({
              next: (e) => {
                this.notificationService.showSuccess(
                  `Coach deletion submitted.`
                );
                this.router.navigate(['/']);
              },
              error: (e) => {
                this.notificationService.showError(`Error in coach deletion.`);
                console.error(e);
              }
            });
          }
        })
      )
    );
    return;
  };
}
