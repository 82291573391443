import { Component, Input, OnInit } from '@angular/core';
import { OAuthService } from 'angular-oauth2-oidc';
import { Align } from '@progress/kendo-angular-popup';
import { lastValueFrom } from 'rxjs';
import { UserService } from 'src/app/core/services/user';

@Component({
  selector: 'app-profile-button',
  templateUrl: './profile-button.component.html',
  styleUrls: ['./profile-button.component.scss']
})
export class ProfileButtonComponent implements OnInit {
  @Input() user: { id: string, name: string, email: string };
  public show: boolean = false;
  public popupAlign: Align = { horizontal: 'right', vertical: 'top' };
  public anchorAlign: Align = { horizontal: 'right', vertical: 'bottom' };

  constructor(
    private oAuthService: OAuthService,
    private userService: UserService
  ) {}

  onLogout(): void {
    this.oAuthService.logOut();
  }

  async ngOnInit(): Promise<void> {
    this.user =
      this.user || (await lastValueFrom(this.userService.getCurrentUser()));
  }
}
