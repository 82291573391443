import { Component, HostBinding, Input } from '@angular/core';
import { ChipSize, ChipThemeColor } from '@progress/kendo-angular-buttons';

@Component({
  selector: 'app-label-chip',
  templateUrl: './label-chip.component.html',
  styleUrls: ['./label-chip.component.scss'],
  host: {
    "[class.row-reverse]": "reverse",
  }
})
export class LabelChipComponent {
  @Input() label: string;
  @Input() chipContent: string | number;
  @Input() chipThemeColor: ChipThemeColor = 'none';
  @Input() size: ChipSize = 'medium';
  @Input() loading?: boolean;
  @Input() reverse?: boolean;

  constructor() {}
}
