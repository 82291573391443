import { Injectable } from "@angular/core"
import { DisplayDataResolver } from "@pl/pl-lib/common"
import { isEmpty } from "lodash"
import { AppSetting } from "../../models/appsetting"

@Injectable({
  providedIn: "root",
})
export class AppSettingDisplayDataResolver
  implements DisplayDataResolver<AppSetting>
{
  typeDisplayName = "AppSetting"

  constructor() {}

  getIcon(entity: AppSetting): string | undefined {
    return "k-i-inherited"
  }

  getImage(entity: AppSetting): string | undefined {
    return undefined
  }

  getSubTitle(entity: AppSetting): string | undefined {
    return undefined
  }

  getTitle(entity: AppSetting): string {
    return entity.key
  }

  getShortTitle(entity: AppSetting): string {
    return entity.id
  }

  getItemHref(entityOrID: AppSetting | string): string | undefined {
    if (isEmpty(entityOrID)) {
      return "/app/appsetting"
    }
    if (typeof entityOrID === "string") return "/app/appsetting/" + entityOrID
    return "/app/appsetting/" + entityOrID.id
  }
}
