import { Injectable } from "@angular/core"
import { HttpClient } from "@angular/common/http"
import {
  BaseCacheableApiService,
  BaseEnvironmentService,
} from "@pl/pl-lib/common"

import { Region } from "../models/region"
import { CreateRegion } from "../models/dtos/region/region.create-dto"
import { UpdateRegion } from "../models/dtos/region/region.update-dto"

@Injectable({
  providedIn: "root",
})
export class RegionService extends BaseCacheableApiService<
  Region,
  CreateRegion,
  UpdateRegion
> {
  constructor(http: HttpClient, environmentService: BaseEnvironmentService) {
    super("regions", http, environmentService, ["providerId"])
  }
}
