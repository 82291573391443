import {GLOBAL_SEARCH_QUICK_LINKS} from "@pl/pl-lib/global-search";

export default [
  {
    provide: GLOBAL_SEARCH_QUICK_LINKS,
    useValue: [
      {
        typeName: 'User',
        title: 'Users'
      },
      {
        typeName: 'Group',
        title: 'Groups'
      },
      {
        typeName: 'Application',
        title: 'Applications'
      },
      {
        typeName: 'Organisation',
        title: 'Organisations'
      }
    ]
  }
];
