import { Component, Inject, Input, OnDestroy, OnInit } from '@angular/core';
import {
  catchError,
  map,
  Observable,
  Subject,
  take,
  takeUntil,
  throwError
} from 'rxjs';
import {
  BaseAuditableApiService,
  BaseAuditableDataService,
  BaseAuditableEntity,
  IHistoryItem
} from '@pl/pl-lib/common';

@Component({
  selector: 'app-base-history-section',
  templateUrl: './base-history-section.component.html',
  styleUrls: ['./base-history-section.component.scss'],
  providers: [{ provide: 'EMPTY_RESOLVER', useValue: undefined }]
})
export class BaseHistorySectionComponent<
  T extends BaseAuditableEntity,
  TService extends BaseAuditableDataService<
    T,
    any,
    any,
    BaseAuditableApiService<T, any, any>
  >
> implements OnInit, OnDestroy
{
  private _isExpanded = false;

  get isExpanded(): boolean {
    return this._isExpanded;
  }

  @Input()
  set isExpanded(value: boolean) {
    this._isExpanded = value;
    if (value && this.lazyLoad && !this.isLoaded) {
      this.refresh();
    }
  }

  private errors: any;
  private destroyed$ = new Subject<void>();

  @Input()
  public id: string;
  @Input()
  public service: TService;
  @Input()
  public refreshTrigger: Observable<any>;

  @Input()
  public title: string = 'Audit Trail';

  @Input()
  public lazyLoad: boolean = true;

  public isLoaded = false;

  public items: (IHistoryItem & { isEntityCrud: boolean; showTitle: string })[];
  public loading: boolean;

  private entityCrudTypes = ['create', 'update', 'delete'];

  constructor(
    @Inject('EMPTY_RESOLVER')
    serviceConst: TService
  ) {
    this.service = serviceConst;
  }

  ngOnDestroy() {
    this.destroyed$.next();
  }

  ngOnInit(): void {
    if (!this.lazyLoad) {
      this.refresh();
    }
    this.refreshTrigger?.pipe(takeUntil(this.destroyed$)).subscribe(() => {
      if (this.isExpanded || !this.lazyLoad) {
        this.refresh();
      } else {
        this.isLoaded = false;
      }
    });
  }

  refresh(): void {
    this.loading = true;
    this.isLoaded = false;
    this.service
      .getHistory(this.id)
      .pipe(
        map((items) =>
          items.map((e) => ({
            ...e,
            isEntityCrud: this.entityCrudTypes.includes(e.type),
            showTitle: this.getTitle(e)
          }))
        ),
        catchError((e) => {
          this.loading = false;
          this.errors = e;
          return throwError(e);
        }),
        take(1)
      )
      .subscribe((e) => {
        this.loading = false;
        this.isLoaded = true;
        this.items = e;
      });
  }

  private getTitle(e: IHistoryItem) {
    if (e.type.startsWith('link-')) {
      return 'Related item ' + e.title;
    }
    return e.title;
  }
}
