import { Injectable } from '@angular/core';

import { BaseDropdownService, DropdownItem } from '@pl/pl-lib/forms';
import { CoachEducationLevel } from 'src/app/core/models/enums/coach-education-level.enum';
import { CoachGender } from 'src/app/core/models/enums/coach-gender.enum';
import { CoachIdentity } from 'src/app/core/models/enums/coach-identity.enum';
import { CoachState } from 'src/app/core/models/enums/coach-state.enum';
import { JobShortlistState } from 'src/app/core/models/enums/jobshortlist-state.enum';

@Injectable({
  providedIn: 'root'
})
export class DropdownService extends BaseDropdownService {
  constructor() {
    super();
    /// use to map between entityType passed to getForType method and get method name.
    /// by default getForEntityType method will map using 'get'+EntityType.
    // this.entityTypeMap['rag'] = this.getRAG;
  }

  public getCoachState(): DropdownItem[] {
    return this.getDropdownItems(CoachState);
  }

  public getCoachGender(): DropdownItem[] {
    return this.getDropdownItems(CoachGender);
  }

  public getCoachIdentity(): DropdownItem[] {
    return this.getDropdownItems(CoachIdentity);
  }

  public getCoachEducationLevel(): DropdownItem[] {
    return this.getDropdownItems(CoachEducationLevel);
  }

  public getJobShortlistState(): DropdownItem[] {
    return this.getDropdownItems(JobShortlistState);
  }
}

