    <div class="buttons">
      <button
        *ngIf="current >= 0 && current !== stepsLength - 1"
        kendoButton
        themeColor="base"
        size="large"
        (click)="onPrevious.emit()"
        [disabled]="current <= 0">
        Previous
      </button>
      <button
        *ngIf="current < stepsLength - 2"
        kendoButton
        themeColor="primary"
        size="large"
        (click)="onNext.emit()">
        Next
      </button>
      <button
        *ngIf="current === stepsLength - 2"
        kendoButton
        themeColor="primary"
        icon="save"
        size="large"
        (click)="onSave.emit()">
        Submit
      </button>
      <button
        *ngIf="current === stepsLength - 1"
        kendoButton
        themeColor="primary"
        icon="check"
        size="large"
        (click)="onFinish.emit()">
        Close
      </button>
    </div>

