import { Injectable } from "@angular/core"
import { HttpClient, HttpErrorResponse } from "@angular/common/http"
import { Observable, catchError, shareReplay, throwError } from "rxjs"
import {
  BaseCacheableApiService,
  BaseEnvironmentService,
} from "@pl/pl-lib/common"
import { User } from "../models/user"
import { EnvironmentService } from './environment';

@Injectable({
  providedIn: "root",
})
export class UserService extends BaseCacheableApiService<User, User, User> {
  private currentUserObservable: Observable<User> | undefined

  constructor(http: HttpClient, environmentService: BaseEnvironmentService) {
    super("users", http, environmentService, ["providerId"])
  }

  public getCurrentUser(refresh = false): Observable<User> {
    if (this.currentUserObservable && !refresh) {
      return this.currentUserObservable
    }

    return (this.currentUserObservable =
      refresh || !this.currentUserObservable
        ? this.http
            .get<User>(this.environmentService.buildApiUrl(`users/current`))
            .pipe(shareReplay())
        : this.currentUserObservable)
  }

  public register(form: any): Observable<any> {
    const url = (this.environmentService as EnvironmentService).registrationLogicAppUrl;
    return this.http.post<any>(url, form).pipe(catchError(this.handleError));
  }

  private handleError(error: HttpErrorResponse) {
    if (error.status === 0) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong.
      console.error(
        `Backend returned code ${error.status}, body was: `,
        error.error
      );
    }
    // Return an observable with a user-facing error message.
    return throwError(
      () => new Error('Something bad happened; please try again later.')
    );
  }
}
