import { Injectable, Optional } from "@angular/core"
import { HttpClient } from "@angular/common/http"
import { BaseApiService } from "@pl/pl-lib/common"
import { AppSetting } from "../models/appsetting"
import {
  catchError,
  firstValueFrom,
  Observable,
  ReplaySubject,
  shareReplay,
} from "rxjs"
import { ErrorsService } from "@pl/pl-lib/errors"
import { CreateAppSetting } from "../models/dtos/appsetting/appsetting.create-dto"
import { UpdateAppSetting } from "../models/dtos/appsetting/appsetting.update-dto"
import { EnvironmentService } from "./environment"

@Injectable({
  providedIn: "root",
})
export class AppSettingService extends BaseApiService<
  AppSetting,
  CreateAppSetting,
  UpdateAppSetting
> {
  public appSetting$ = new ReplaySubject<AppSetting>()
  currentAppSetting: AppSetting
  isLoaded: boolean

  constructor(
    http: HttpClient,
    environmentService: EnvironmentService,
    @Optional() private errorService?: ErrorsService,
  ) {
    super("appsetting", http, environmentService)
  }

  public load(): Observable<AppSetting> {
    const result = this.http
      .get<AppSetting>(this.environmentService.buildApiUrl("appsettings/object"))
      .pipe(shareReplay(1))
    result.subscribe((e) => {
      this.appSetting$.next(e)
      this.setConfig(e)
    })
    return result
  }

  public async loadAndHandleErrors(): Promise<boolean> {
    if (!this.errorService) {
      throw new Error(
        "Errors service not present in injector!. Please make sure that you import it in the app or please call load() method directly.",
      )
    }
    const r = await firstValueFrom(
      this.load().pipe(
        catchError(
          this.errorService.handleObservableCatchError("AppConfig", "1"),
        ),
      ),
    )
    if (!r) {
      return false
    }
    if (!this.isLoaded) {
      await this.errorService.redirectToError({
        category: "AppConfig",
        code: "2",
      })
      return false
    }
    return true
  }

  override create(record: CreateAppSetting): Observable<AppSetting> {
    throw new Error('Create on LinkedRecord not supported.');
  }

  override update(record: UpdateAppSetting): Observable<AppSetting> {
    throw new Error('Update on LinkedRecord not supported.');
  }

  private setConfig(c: AppSetting) {
    this.currentAppSetting = c
    this.isLoaded = true
  }
}
