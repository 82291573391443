<div>
    <div *ngIf="imagePreview" class="img-preview">
      <h3>Logo</h3>
      <img
          [src]="imagePreview?.['src']"
          alt="image preview"
          style="width: 200px; margin: 10px"
      />
    </div>
    <kendo-upload
        (clear)="clear()"
        (select)="select($event)"
        [autoUpload]="false"
        [multiple]="false"
        [restrictions]="imageRestrictions"
    >
    </kendo-upload>
  </div>
  