import { Injectable } from "@angular/core"
import { HttpClient } from "@angular/common/http"
import {
  BaseCacheableApiService,
  BaseEnvironmentService,
} from "@pl/pl-lib/common"

import { Country } from "../models/country"
import { CreateCountry } from "../models/dtos/country/country.create-dto"
import { UpdateCountry } from "../models/dtos/country/country.update-dto"

@Injectable({
  providedIn: "root",
})
export class CountryService extends BaseCacheableApiService<
  Country,
  CreateCountry,
  UpdateCountry
> {
  constructor(http: HttpClient, environmentService: BaseEnvironmentService) {
    super("countries", http, environmentService, ["providerId"]);
  }
}
