<pl-lazy-load-expansion-panel
  [title]="title"
  [icon]="icon"
  [refreshTrigger]="refreshTrigger"
  [lazyLoad]="lazyLoad"
  [actionsTemplate]="actionsTemplate"
  [bodyTemplate]="bodyTemplate || body"
  [errors]="errors"
  [headerTemplate]="headerTemplate || header"
  [isExpanded]="isExpanded"
  [itemsGetter]="itemsGetter"
  [itemsCounterConfig]="itemsCounterConfig"
>
</pl-lazy-load-expansion-panel>

<ng-template #header let-items="items" let-count="count" let-loading="countLoading">
  <div class="header">
    <span *ngIf="icon" class="k-i-{{icon}} k-icon"></span>
    <app-label-chip
      [label]="title"
      [chipContent]="this.chipSettings?.content || count || items?.count ?? 0"
      [loading]="loading"
      [reverse]="true"
      size="small"
      [chipThemeColor]="this.chipSettings?.theme || 'success'">
    </app-label-chip>
  </div>
</ng-template>

<ng-template #body >
  <pl-entity-list #grid *ngIf="configurator.gridConfigurator.render" [configurator]="configurator.gridConfigurator"></pl-entity-list>
</ng-template>
