import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'app-pl-stepper-buttons',
  templateUrl: './pl-stepper-buttons.component.html',
  styleUrls: ['./pl-stepper-buttons.component.scss']
})
export class PlStepperButtonsComponent implements OnInit {

  @Input()
  public current:number;

  @Input()
  public stepsLength:number;

  @Output()
  public onPrevious = new EventEmitter<any>();
  @Output()
  public onNext = new EventEmitter<any>();
  @Output()
  public onSave = new EventEmitter<any>();
  @Output()
  public onFinish = new EventEmitter<any>();

  constructor() { }

  ngOnInit(): void {
  }

}
