<div kendoTooltip *ngIf="data && data.length>0">
  <kendo-dropdownbutton
    themeColor="base"
    fillMode="clear"
    size="large"
    title="External Links"
    [data]="data">
    <pl-icon icon="k-i-hyperlink-globe"></pl-icon>
  </kendo-dropdownbutton>
</div>
