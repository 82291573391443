import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { PlFormFieldConfig } from '@pl/pl-lib/forms';
import { appInjector, PermissionsService } from '@pl/pl-lib/common';

@Component({
  selector: 'app-logo-upload',
  template: `
    <form class="k-form mt-2" *ngIf="formGroup" [formGroup]="formGroup">
      <div class="images">
        <div
          class="img-preview"
          fxLayout="column"
          fxLayoutAlign="center center"
          *ngIf="showLogo"
        >
          <h3>Profile Image</h3>
          <img
            *ngIf="formGroup.get('imageUrl')?.value"
            [src]="formGroup.get('imageUrl')?.value"
            alt="image preview"
            style="width: 200px; margin: 10px"
          />
          <img
            *ngIf="!formGroup.get('imageUrl')?.value"
            alt="No Image"
            src="/assets/images/ci-logo-placeholder.png"
            style="width: 150px; margin: 10px"
          />
        </div>
        <div fxLayout fxLayoutAlign="end">
          <button
            (click)="removeImage(); $event.stopPropagation()"
            *ngIf="formGroup.get('imageUrl')?.value && showLogo"
            fillMode="flat"
            icon="delete"
            kendoButton
            size="medium"
            themeColor="primary"
          >
            Remove Image
          </button>
        </div>

        <app-uploader
          *ngIf="hasWritePermissions"
          (imageUploadedEvent)="imageUploaded($event)"
        ></app-uploader>
      </div>
    </form>
  `,
  styles: [
    `
      .images {
        margin: auto;
        width: 320px;
      }
    `
  ]
})
export class LogoUploadComponent implements OnInit {
  public formGroup: FormGroup;
  public fieldConfig: PlFormFieldConfig;
  public showLogo = true;
  public hasWritePermissions = true;

  constructor() {}

  async ngOnInit(): Promise<void> {
        const permissionsService = appInjector().get(PermissionsService);

        /*
    if ((this.fieldConfig as any)['data']['entityType'] === 'Organisation') {
      this.hasWritePermissions = await permissionsService.hasPermission(
        '223PLCMS.Organisations',
        'Write'
      );
    } else if ((this.fieldConfig as any)['data']['entityType'] === 'Team') {
      this.hasWritePermissions = await permissionsService.hasPermission(
        '223PLCMS.Teams',
        'Write'
      );
    } else if ((this.fieldConfig as any)['data']['entityType'] === 'League') {
      this.hasWritePermissions = await permissionsService.hasPermission(
        '223PLCMS.Leagues',
        'Write'
      );
    }*/
  }

  removeImage() {
    this.formGroup.get('imageUrl')?.setValue('');
  }

  imageUploaded(image: string | ArrayBuffer | null) {
    if (image != null) {
      this.showLogo = false;
      if (typeof image == 'string') {
        this.formGroup.get('rawImage')?.setValue(image);
      }
    }
  }
}
