import { Component, Input } from '@angular/core';
import {
  PlEntityListConfigurator,
  PlEntityListPageConfigurator
} from '../pl-entity-list/pl-entity-list.configurator';
import { ActionButton, AppTitleService, BaseEntity } from '@pl/pl-lib/common';
import { ActivatedRoute, Data, Router } from '@angular/router';
import { appInjector } from '@pl/pl-lib/common';

@Component({
  selector: 'app-pl-entity-list-page',
  templateUrl: './pl-entity-list-page.component.html',
  styleUrls: ['./pl-entity-list-page.component.scss']
})
export class PlEntityListPageComponent<T extends BaseEntity> {
  @Input()
  public listConfigurator: PlEntityListConfigurator;

  @Input()
  public title: string;
  @Input()
  public subTitle: string;
  @Input()
  public actions: ActionButton[];

  constructor(
    protected router: Router,
    protected route: ActivatedRoute,
    protected appTitleService: AppTitleService
  ) {
    this.route.data.subscribe((data: Data) => {
      const configurator = data[
        'listConfigurator'
      ] as new () => PlEntityListConfigurator;
      this.title = data['title'] as string;
      this.subTitle = data['subTitle'] as string;
      this.actions = data['actions'] as ActionButton[];
      if (configurator) {
        this.listConfigurator = appInjector().get(configurator);
        const pageConfigurator = this
          .listConfigurator as PlEntityListPageConfigurator;
        if (pageConfigurator.title) {
          this.title = pageConfigurator.title;
        }
        if (pageConfigurator.actions) {
          this.actions = pageConfigurator.actions;
        }

        this.appTitleService.setPageTitle(this.title);
      }
    });
  }
}
