export function dynamicLabelHandler(
  value: string,
  type: string,
  subType?: any
): string {
  if (subType) {
    subType = subType;
  }

  return value;
}
