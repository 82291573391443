import { Component, Injector } from '@angular/core';
import { NgControl } from '@angular/forms';

import { JobDropdownComponentConfigurator } from './job-dropdown-component.configurator';
import { BaseMultiselectDropdownComponent } from '@pl/pl-lib/forms';
import { Job } from 'src/app/core/models/job';

@Component({
  selector: 'app-job-multiselect-dropdown',
  templateUrl:
    '../../../../../../node_modules/@pl/pl-lib/assets/templates/searchable-dropdown/multiselect-dropdown.component.html',
  styleUrls: [
    '../../../../plLibSetup/components/searchable-dropdown/multiselect-dropdown.component.scss'
  ]
})
export class JobMultiselectDropdownComponent extends BaseMultiselectDropdownComponent<Job> {
  constructor(ngControl: NgControl, injector: Injector) {
    super(ngControl, injector);
    this.configurator = new JobDropdownComponentConfigurator();
  }
}
