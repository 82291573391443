import {
  HTTP_INTERCEPTORS,
  HttpClient,
  HttpClientModule
} from '@angular/common/http';
import { LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import {
  AlreadyAuthenticatedGuard,
  AuthConfig,
  AuthGuard, BaseEnvironmentService, CanDeactivateGuard,
  CommonModule, JsonDateInterceptor,
  TokenizedInterceptor
} from '@pl/pl-lib/common';
import { TemplatePagesModule } from '@pl/pl-lib/template-pages';
import { ButtonsModule } from '@progress/kendo-angular-buttons';
import { OAuthModule } from 'angular-oauth2-oidc';
import { CommonModule as AngularCommonModule } from '@angular/common';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './features/login/login.component';
import { AppInitialiser } from './app.initialiser';
import { SharedModule } from './shared/shared.module';
import { CoreModule } from './core/core.module';

import { registerLocaleData } from '@angular/common';
import localeGb from '@angular/common/locales/en-GB';
import { RouteReuseStrategy } from '@angular/router';
import { ChartsModule } from '@progress/kendo-angular-charts';
import 'hammerjs';
import { dynamicLabelHandler } from './plLibSetup/pipes/dynamicLabelHandler';
import { DisplayDataResolversMap } from './plLibSetup/resolvers/display-data-resolver-map';
import { BaseDropdownService, FormsModule } from '@pl/pl-lib/forms';
import { DropdownService } from './plLibSetup/services/dropdown.service';
import { DataGridsModule } from '@pl/pl-lib/data-grids';
import { WindowCanDeactivateGuard } from "@pl/pl-lib/dialogs";

import "@progress/kendo-angular-intl/locales/en-GB/all";
import { RouteReuseService } from './core/services/route-reuse.service';
import searchProviders from './search-providers';
import { GlobalSearchModule } from '@pl/pl-lib/global-search';
import { AboutComponent } from './features/about/about.component';
import { ProfileComponent } from './features/profile/profile.component';
import { HomeComponent } from './features/home/home.component';
import { EnvironmentService } from './core/services/environment';

registerLocaleData(localeGb, 'en-GB');

// required for AOT compilation
export function HttpLoaderFactory(http: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(http);
}

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    AboutComponent,
    ProfileComponent,
    HomeComponent
  ],
  imports: [
    AngularCommonModule,
    BrowserAnimationsModule,
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    OAuthModule.forRoot(),
    TemplatePagesModule,
    CoreModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    CommonModule.forRoot(
      {
        authenticatedRoute: 'app/home',
        unauthenticatedRoute: 'login',
        unauthenticatedQueryParams: { skipLoginPage: true },
        forbiddenRoute: 'forbidden'
      } as AuthConfig,
      {
        usePlac: true,
        ttl: 60 //what's our standard @Lukasz?,
      },
      { dynamicLabelHandler: dynamicLabelHandler },
      { displayDataResolversMap: DisplayDataResolversMap },
      undefined,
      { provide: BaseEnvironmentService, useExisting: EnvironmentService }
    ),
    FormsModule.forRoot({
      dropdownService: {
        provide: BaseDropdownService,
        useClass: DropdownService
      }
    }),
    DataGridsModule.forRoot({
      gridsConfigLocation: '/assets/config/data-grids.json'
    }),
    ButtonsModule,
    SharedModule,
    ChartsModule,
    GlobalSearchModule.forRoot(
      [
        {
          typeName: 'User',
          title: 'Users'
        },
        {
          typeName: 'Organisation',
          title: 'Organisations'
        }
      ],
      []
    )
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: TokenizedInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: JsonDateInterceptor, multi: true },
    AppInitialiser,
    CanDeactivateGuard,
    WindowCanDeactivateGuard,
    AuthGuard,
    AlreadyAuthenticatedGuard,
    ...searchProviders,
    { provide: LOCALE_ID, useValue: 'en-GB' },
    { provide: RouteReuseStrategy, useClass: RouteReuseService }
  ],
  exports: [],
  bootstrap: [AppComponent]
})
export class AppModule { }


