import { Component, Injector } from '@angular/core';
import { NgControl } from '@angular/forms';

import { CoachDropdownComponentConfigurator } from './coach-dropdown-component.configurator';
import { BaseMultiselectDropdownComponent } from '@pl/pl-lib/forms';
import { Coach } from 'src/app/core/models/coach';

@Component({
  selector: 'app-coach-multiselect-dropdown',
  templateUrl:
    '../../../../../../node_modules/@pl/pl-lib/assets/templates/searchable-dropdown/multiselect-dropdown.component.html',
  styleUrls: [
    '../../../../plLibSetup/components/searchable-dropdown/multiselect-dropdown.component.scss'
  ]
})
export class CoachMultiselectDropdownComponent extends BaseMultiselectDropdownComponent<Coach> {
  constructor(ngControl: NgControl, injector: Injector) {
    super(ngControl, injector);
    this.configurator = new CoachDropdownComponentConfigurator();
  }
}
