import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from './features/login/login.component';
import { AlreadyAuthenticatedGuard, AuthGuard, CanDeactivateGuard } from '@pl/pl-lib/common';
import { WindowCanDeactivateGuard } from '@pl/pl-lib/dialogs';
import { ErrorsModule } from '@pl/pl-lib/errors';
import { HomeComponent } from './features/home/home.component';

const routes: Routes = [
  {
    path: '',
    component: HomeComponent,
    data: {
      skipBreadcrumb: true,
      external: true,
      organisation: 'Coach Index'
    }
  },
  {
    path: 'external',
    loadChildren: () =>
      import('./features/external/external.module').then(
        (m) => m.ExternalModule
      )
  },
  {
    path: 'login',
    component: LoginComponent,
    canActivate: [AlreadyAuthenticatedGuard],
    data: { title: 'Login' }
  },
  {
    path: 'logout',
    component: LoginComponent,
    canActivate: [AlreadyAuthenticatedGuard],
    data: { title: 'Login' }
  },
  {
    path: 'error',
    loadChildren: () => ErrorsModule
  },
  {
    path: 'app',
    canActivate: [AuthGuard],
    canDeactivate: [WindowCanDeactivateGuard, CanDeactivateGuard],
    children: [
      {
        path: '',
        canDeactivate: [WindowCanDeactivateGuard, CanDeactivateGuard],
        loadChildren: () =>
          import('./features/layout/layout.module').then((m) => m.LayoutModule)
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
