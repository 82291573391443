import { Injectable } from "@angular/core"
import { DisplayDataResolver } from "@pl/pl-lib/common"
import { isEmpty } from "lodash"
import { CoachDesiredRegion } from "../../models/coachdesiredregion"

@Injectable({
  providedIn: "root",
})
export class CoachDesiredRegionDisplayDataResolver
  implements DisplayDataResolver<CoachDesiredRegion>
{
  typeDisplayName = "CoachDesiredRegion"

  constructor() {}

  getIcon(entity: CoachDesiredRegion): string | undefined {
    return "k-i-inherited"
  }

  getImage(entity: CoachDesiredRegion): string | undefined {
    return undefined
  }

  getSubTitle(entity: CoachDesiredRegion): string | undefined {
    return undefined
  }

  getTitle(entity: CoachDesiredRegion): string {
    return entity.region.name
  }

  getShortTitle(entity: CoachDesiredRegion): string {
    return entity.id
  }

  getItemHref(entityOrID: CoachDesiredRegion | string): string | undefined {
    if (isEmpty(entityOrID)) {
      return "/app/coachDesiredRegion"
    }
    if (typeof entityOrID === "string") return "/app/coachDesiredRegion/" + entityOrID
    return "/app/coachDesiredRegion/" + entityOrID.id
  }
}
