export enum CoachIdentity {
    'Asian/Asian British – Pakistan' = 0,
    'Asian/Asian British – Bangladeshi' = 1,
    'Asian/Asian British – Chinese' = 2,
    'Asian/Asian British – Indian' = 3,
    'Asian/Asian British – any other Asian background' = 4,
    'Black or Black British - African' = 5,
    'Black or Black British - Caribbean' = 6,
    'Black/African/Caribbean background' = 7,
    'Mixed - White and Black African' = 8,
    'Mixed - White and Black Caribbean' = 9,
    'Mixed - White and Asian' = 10,
    'Mixed - any other mixed/multiple ethnic background' = 11,
    'Other - Arab' = 12,
    'Other - any other ethnic group' = 13,
    'Prefer not to say' = 14,
    'White - English/Welsh/Scottish/N.Irish/British' = 15,
    'White - Irish' = 16,
    'White - Gypsy or Irish Traveller' = 17,
    'White - any other white background' = 18
}