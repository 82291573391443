import { BaseDropdownComponentConfigurator } from '@pl/pl-lib/forms';
import { CourseDisplayDataResolver } from 'src/app/core/resolvers/displayData/course';
import { CourseService } from 'src/app/core/services/course';

export class CourseDropdownComponentConfigurator extends BaseDropdownComponentConfigurator {
  constructor() {
    super();
    this.dataServiceType = CourseService
    this.displayDataResolverType = CourseDisplayDataResolver
  }
}
