import { Injectable } from "@angular/core"
import { HttpClient } from "@angular/common/http"
import {
  BaseCacheableApiService,
  BaseEnvironmentService,
} from "@pl/pl-lib/common"

import { Course } from "../models/course"
import { CreateCourse } from "../models/dtos/course/course.create-dto"
import { UpdateCourse } from "../models/dtos/course/course.update-dto"

@Injectable({
  providedIn: "root",
})
export class CourseService extends BaseCacheableApiService<
  Course,
  CreateCourse,
  UpdateCourse
> {
  constructor(http: HttpClient, environmentService: BaseEnvironmentService) {
    super("courses", http, environmentService, ["providerId"])
  }
}
