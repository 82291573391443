import { Injectable } from "@angular/core"
import { HttpClient } from "@angular/common/http"
import {
  BaseCacheableApiService,
  BaseEnvironmentService,
} from "@pl/pl-lib/common"

import { Department } from "../models/department"
import { CreateDepartment } from "../models/dtos/department/department.create-dto"
import { UpdateDepartment } from "../models/dtos/department/department.update-dto"

@Injectable({
  providedIn: "root",
})
export class DepartmentService extends BaseCacheableApiService<
  Department,
  CreateDepartment,
  UpdateDepartment
> {
  constructor(http: HttpClient, environmentService: BaseEnvironmentService) {
    super("departments", http, environmentService, ["providerId"])
  }
}
