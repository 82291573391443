import {
    Component,
    Inject,
    Injector,
    LOCALE_ID,
    OnInit,
    Optional,
    Self,
    SkipSelf
  } from '@angular/core';
  import { DropdownService } from 'src/app/plLibSetup/services/dropdown.service';
  import { ControlContainer, NgControl } from '@angular/forms';
  import { TranslateService } from '@ngx-translate/core';
  import { BaseNotificationService as NotificationService } from '@pl/pl-lib/common';
  import { ConfigurableDropdownListComponent } from '@pl/pl-lib/forms';
  
  @Component({
    selector: 'app-jobshortlist-state-dropdown-list',
    templateUrl:
      '../../../../plLibSetup/components/dropdown-list/dropdown-list.component.html',
    viewProviders: [
      {
        provide: ControlContainer,
        useFactory: (container: ControlContainer) => container,
        deps: [[new SkipSelf(), ControlContainer]]
      }
    ]
  })
  export class JobShortlistStateDropdownListComponent
    extends ConfigurableDropdownListComponent
    implements OnInit
  {
    constructor(
      injector: Injector,
      notificationService: NotificationService,
      @Inject(LOCALE_ID) locale: string,
      dropdownService: DropdownService,
      translateService: TranslateService,
      @Self() @Optional() ngControl: NgControl
    ) {
      super(
        injector,
        notificationService,
        locale,
        dropdownService,
        translateService,
        ngControl
      );
      this.entityType = 'JobShortlistState';
    }
  }
  