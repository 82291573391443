import { BaseDropdownComponentConfigurator } from '@pl/pl-lib/forms';
import { DepartmentDisplayDataResolver } from 'src/app/core/resolvers/displayData/department';
import { DepartmentService } from 'src/app/core/services/department';

export class DepartmentDropdownComponentConfigurator extends BaseDropdownComponentConfigurator {
  constructor() {
    super();
    this.dataServiceType = DepartmentService
    this.displayDataResolverType = DepartmentDisplayDataResolver
  }
}
