import { BaseDropdownComponentConfigurator } from '@pl/pl-lib/forms';
import { JobDisplayDataResolver } from 'src/app/core/resolvers/displayData/job';
import { JobService } from 'src/app/core/services/job';

export class JobDropdownComponentConfigurator extends BaseDropdownComponentConfigurator {
  constructor() {
    super();
    this.dataServiceType = JobService
    this.displayDataResolverType = JobDisplayDataResolver
  }
}
